import React from 'react';
import { useState, useEffect } from 'react';
import StarRatings from 'react-star-ratings';
import Modal from 'react-modal';
import { MdStarOutline } from 'react-icons/md';
import NavigationButton from '../components/navigation-buttons/navigation-button';
import EmbeddingsApi from '../api-client/api-embeddings';
import { useDispatch } from 'react-redux';
import { setGlobalSuccess } from '../state/action-creators/global-action';

const MessageRating = (props) => {

    const [rating, changeRating] = useState(0.0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submittedRating, setSubmittedRating] = useState(null);
    const [submittedFeedback, setSubmittedFeedback] = useState(null);
    const [feedback, setFeedback] = useState("");
    const embeddingsApi = EmbeddingsApi();
    const [submitClick, setSubmitClick] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(props.props.payload !== undefined){
            if(props.props.payload.rating !== undefined){
                changeRating(parseFloat(props.props.payload.rating));
            }
            if(props.props.payload.feedback !== undefined){
                setFeedback(props.props.payload.feedback);
            }
        }
    }, []);

    const onRatingChange = (rating) => {
        changeRating(rating);
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        if(!submitClick){
            if(props.props.payload !== undefined){
                if(props.props.payload.rating !== undefined){
                    if(submittedRating === null)
                        changeRating(parseFloat(props.props.payload.rating));
                    else
                        changeRating(submittedRating);
                }
                if(props.props.payload.feedback !== undefined)
                    if(submittedFeedback === null)
                        setFeedback(props.props.payload.feedback);
                    else
                        setFeedback(submittedFeedback);
            }
        }
        // console.log(rating);
        // console.log(feedback);
        setIsModalOpen(false);
    };

    const onIconClick = () => {
        setSubmitClick(false);
        if(submittedRating === null)
            changeRating(parseFloat(props.props.payload.rating));
        else
            changeRating(submittedRating);
        if(submittedFeedback === null)
            setFeedback(props.props.payload.feedback);
        else
            setFeedback(submittedFeedback);
        openModal();
    }

    const customModalStyle = {
        content: {
            width: '800px',
            height:'500px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
    };

    const handleFeedbackChange = (event) => {
        const value = event.target.value;
        setFeedback(value);
    }

    const submitFeedback = async() => {
        const response = await embeddingsApi.saveFeedback({
                                    messageId: props.props.payload.message_id,
                                    rating: rating.toString(),
                                    feedback: feedback});
        // console.log(response.data);
        if(response.data !== undefined){
            if(response.data.status){
                setSubmittedRating(rating);
                setSubmittedFeedback(feedback);
                setSubmitClick(true);
                dispatch(setGlobalSuccess("Your feedback is submitted"));
                closeModal();
            }
        }
    }

    return (
        <>
            <div>
                <MdStarOutline
                    onClick={onIconClick}
                    style = {{color: (props.props.payload.rating !== "0" || (props.props.payload.feedback != null && props.props.payload.feedback !== ""))? "gold" : "grey", height:"1.5em", width: "1.5em", strokeWidth: "2px"}}
                />
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customModalStyle}
            >
                <>
                    <div className='modal-header'>
                        <strong>Please provide chat feedback</strong>
                            <svg onClick={closeModal}
                                width="24"
                                height="24"
                                color='#fff'
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{ cursor: "pointer" }} // Add cursor pointer style
                            >
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                    </div>
                    <div style={{display:"flex",flexDirection: "column", 
                                alignItems: "center", justifyContent:"center"}}>
                        <StarRatings
                            style={{marginTop:"100px"}}
                            rating={rating}
                            starRatedColor="gold"
                            starEmptyColor="grey"
                            starHoverColor="gold"
                            changeRating={onRatingChange}
                            numberOfStars={5}
                            starDimension='40px'
                            starSpacing='4px'
                            name='rating'
                        />
                        <textarea
                            style={{width: "600px", height: "200px",
                                    margin:"50px"}}
                            value={feedback? feedback : ""}
                            onChange={handleFeedbackChange}
                            maxLength={300}
                        />
                        <div style={{width:"fit-content"}}>
                            <NavigationButton text={"Submit"} 
                                handleActionClick={submitFeedback} 
                                disabled={rating === 0 && (feedback === null || feedback === "")}/>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default MessageRating;