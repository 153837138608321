import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ContextProvider } from './Context';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
        <ContextProvider>
                <Provider store={store}>
                        <App />
                                <ToastContainer
                                        position="bottom-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="light"
                                />
                </Provider>
        </ContextProvider>,
        document.getElementById("root")
);


