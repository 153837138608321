import { useState } from 'react';
import axios from 'axios';
// import AcquireGraphAPIToken from '../auth/AcquireGraphAPIToken';
import AcquireAPIToken from '../auth/AcquireAPIToken';
import { useDispatch, useSelector } from 'react-redux';
import { setTeamRecordsFail, setTeamRecordsSuccess } from '../state/action-creators/teamGroups-actions';
import { setTeamsSuccess, setTeamsFail } from '../state/action-creators/teamList-action';
import { useContext } from 'react';
import { Context } from '../Context';
import { refreshChatDetails, setChatContent, setChatId, setChatResponse } from '../state/action-creators/chat-action';
import { setEmbeddings } from '../state/action-creators/embedding-actions';
import { setFileSessionDetail } from '../state/action-creators/file-session-detail-action';
import { setChatListFailure, setChatListLoading, setChatListSuccess } from '../state/action-creators/chat-list-actions';
import { setDismissToast, setGlobalError, setGlobalLoading, setGlobalSuccess } from '../state/action-creators/global-action';
import { setAllOpenAiParameters } from '../state/action-creators/openAI-parameter-action';
import { setAllChunkingSettings } from '../state/action-creators/chunking-settings-action';
import { v4 as uuid } from 'uuid';
import { setChatResponseError, setChatResponseSave } from '../state/action-creators/chat-loading-action';
import { setSessionFiles } from '../state/action-creators/session-files-action';

const EmbeddingsApi = () => {
  const botName = process.env.REACT_APP_BOTNAME;
  const greeting = process.env.REACT_APP_INITIAL_GREETING;
  const initial_message = process.env.REACT_APP_INITIAL_MESSAGE;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [headers, setHeaders] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {userName} = useContext(Context);
  const { user } = useContext(Context);
  const selectedFiles = useSelector((state) => state.selected_files.selectedFiles);
  const selectedCaseCloud = useSelector((state) => state.selected_files.selectedCaseCloud);
  const currentSessionname = useSelector((state) => state.selected_files.sessionName);

  const currentChatId = useSelector((state) => state.chat_details.chatId);
  const currentChatName = useSelector((state) => state.chat_details.chatName);
  const currentSelectedEmbedding = useSelector((state) => state.embedding_details.selectedEmbedding);
  const lastChat = useSelector((state) => state.chat_details.lastChat);
  const chatContent = useSelector((state) => state.chat_details.chatResponse);
  const selectedTeam = useSelector((state) => state.team_list.selectedTeam);
  const chatList = useSelector((state) => state.chatlist.chatList);

    const selectedpptxstrategy = useSelector((state) => state.chunkingSettings.selectedpptxStrategy);
    const selectedpdfstrategy = useSelector((state) => state.chunkingSettings.selectedpdfStrategy);
    const selecteddocxstrategy = useSelector((state) => state.chunkingSettings.selecteddocxStrategy);
    const selectedxlsxstrategy = useSelector((state) => state.chunkingSettings.selectedxlsxStrategy);
    const selectedcsvstrategy = useSelector((state) => state.chunkingSettings.selectedcsvStrategy);
    const selectedembeddingmodel = useSelector((state) => state.chunkingSettings.selectedembeddingModel);
    const chunkSize = useSelector((state) => state.chunkingSettings.chunkSize);
    const chunkOverlap = useSelector((state) => state.chunkingSettings.chunkOverlap);
    const anonymizeChunk = useSelector((state) => state.chunkingSettings.anonymizeChunks);
    const treatTableSeparately = useSelector((state) => state.chunkingSettings.treatTableSeparately);

    const temperature = useSelector((state) => state.openAI.temperature);
    const maxP = useSelector((state) => state.openAI.maxP);
    const maxTokens = useSelector((state) => state.openAI.maxTokens);
    const frePenalty = useSelector((state) => state.openAI.frequencyPenalty);
    const prePenalty = useSelector((state) => state.openAI.presencePenalty);
    const chunkSearchSize = useSelector((state) => state.openAI.chunkSearchSize);

  const generateRequest = () =>{
    let driveId = "";
    const filesPayload = selectedFiles.map((file)=>{
        const name = file.item.name;
        const itemId = file.item.id;
        driveId = file.item.parentReference.driveId;
        const size = file.item.size;
            return {"name": name, "item_id":itemId, "size":size};
    });
    const payload = {
        team_id: selectedCaseCloud.id,
        team_name: selectedCaseCloud.name,
        session_id: currentSessionname.id,
        session_name: currentSessionname.name,
        user_email: userName,
        drive_id: driveId,
        value: filesPayload,
        anonymize_chunks: anonymizeChunk,
        embedding_model: selectedembeddingmodel.label,
        pptx_chunking_strategy : selectedpptxstrategy.label,
        pdf_chunking_strategy: selectedpdfstrategy.label,
        docx_chunking_strategy: selecteddocxstrategy.label,
        xlsx_chunking_strategy : selectedxlsxstrategy.label,
        csv_chunking_strategy: selectedcsvstrategy.label,
        chunk_size: parseInt(chunkSize),
        chunk_overlap: parseInt(chunkOverlap),
        treat_tables_separately: treatTableSeparately
    }
    return payload;
  }

  const generateRandomId = () => {
    let x = uuid();
    return x;
  }

  const generateSaveChatRequest = (userMessage, botMessage, chat_name, message_id, start_time, end_time, userEmail) =>{
    let chatId = currentChatId;

    let Prompt = [
      {
        "content": userMessage,
        "role": "user"
      },
      {
        "query": userMessage,
        "content": botMessage,
        "role":"assistant",
        "chat_start_time": start_time,
        "chat_end_time": end_time,
        "user_email": userEmail,
        "message_id": message_id,
        "temp": parseFloat(temperature),
        "max_p": parseFloat(maxP),
        "max_tokens": parseInt(maxTokens),
        "frequency_penalty": parseFloat(frePenalty),
        "presence_penalty": parseFloat(prePenalty), 
        "k": parseInt(chunkSearchSize)
      }
    ]

    if(currentChatId === "" || currentChatId === null){
      chatId = generateRandomId();
      dispatch(setChatId(chatId));
      const full_initial_message = initial_message.replace("[#bot#]", botName);
      const initialPrompt = [
        {
          "role": "assistant",
          "content": `${greeting} ${user ? user.split(',')[1] : ""}, ${full_initial_message}`
        },
      ]
      Prompt = [...initialPrompt, ...Prompt];
    }
      return {
        "team_id": selectedTeam.value,
        "chat_id": chatId,
        "session_id": currentSelectedEmbedding.value,
        "chat_name": currentChatName === "" ? chat_name :currentChatName,
        "value": Prompt
      }
  }

  const generateChatRequest = (userMessage)=>{
    return {
      "index_id": currentSelectedEmbedding.index,
      "chat_name": currentChatName ?? "",
      "chat_id": currentChatId,
      "team_id": selectedTeam.value,
      "session_id": currentSelectedEmbedding.value,
      "query": userMessage,
      "temp": parseFloat(temperature),
      "max_p": parseFloat(maxP),
      "max_tokens": parseInt(maxTokens),
      "frequency_penalty": parseFloat(frePenalty),
      "presence_penalty": parseFloat(prePenalty), 
      "k": parseInt(chunkSearchSize)
    }
  }

  const initialize = async () => {
    try {
      const token = await AcquireAPIToken();
      return token;
    } 
    catch (error) {
      console.log(error.message);
    } 
    finally {
      setLoading(false);
    }
    return null;
  };

  const getTeams = async () => {
    let token = await initialize();
    dispatch(setGlobalLoading("Wait! Your teams are loading..."));
    try {
        setLoading(true);
        await axios.get(baseUrl+'api/teams', {headers:{
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          }}).then((response)=>{
            dispatch(setTeamsSuccess(response.data));
          });
          dispatch(setDismissToast());
      } catch (error) {
        dispatch(setTeamsFail(error));
        dispatch(setGlobalError("OOPS! error occured while loading teams"));
      } finally {
        setLoading(false);
      }
  };

  const getGroupRecords = async ({ teamId}) => {
    let token = await initialize();
        try {
            setLoading(true);
            axios.get(`${baseUrl}api/teams/${teamId}/group_records`, {headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }}).then((response)=>{
                dispatch(setTeamRecordsSuccess(response.data.group_records.value, false));
            })
          } catch (error) {
            dispatch(setTeamRecordsFail(error));
          } finally {
            setLoading(false);
        }
  };

  const getUserAccess = async () => {
    let token = await initialize();
        try {
            const response = await axios.get(`${baseUrl}api/user_access`, {headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
              }})
            return response.data;
          } catch (error) {
          } finally {
        }
  };
  const getTeamFilesDetails = async(teamId) => {
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+`api/teamfiles/team/${selectedTeam.value}/files`,
                              {headers:{
                                'Authorization': 'Bearer ' + token,
                                'Content-Type': 'application/json',
                            }}
      );
      dispatch(setFileSessionDetail(response.data));
    }catch (error){
      console.log(error.message);
    }
  }

  const getTeamFiles = async ({ driveId, itemId }) => {
    let token = await initialize();
        try {
            axios.get(`${baseUrl}api/teams/drives/${driveId}/items/${itemId}/folders_files`, 
                    {headers:{
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    }}).then((response)=>{
                          try{
                            dispatch(setTeamRecordsSuccess(response.data.channel_folders_files.value, true));
                          }catch (error){
                            dispatch(setTeamRecordsSuccess([], true));
                          }
                    })
          } catch (error) {
            dispatch(setTeamRecordsFail(error));
          } finally {
            setLoading(false);
        }
  };

  const generateEmbeddings = async() => {
    let token = await initialize();
        try{
            const request = generateRequest();
            const response = await axios.post(baseUrl+"api/teams/processteamsfiles", request, 
                        {headers:{
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json',
                        }});
            return response;
          }catch (error){
                console.log(error.message);
          }finally{
      
          }
  }

  const getChatResponse = async({userMessage, message_id}) =>{
    const token = await initialize();
    try{
        const request = generateChatRequest(userMessage);
        const response = await axios.post(baseUrl+"api/chat", request,
                        {headers:{
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'application/json',
                        }}
        );
        let firstCall = ((lastChat === undefined || lastChat === null || lastChat.length === 0 || lastChat === "") 
                      && (chatContent === undefined || chatContent === null || chatContent.length === 0)) 
                      ? true :false;
        dispatch(setChatResponse(response.data));
        saveChat({userMessage: userMessage,
                  botMessage: response.data[0].content, 
                  chatName:response.data[1].chat, 
                  firstCall:firstCall,
                  message_id: message_id,
                  start_time: response.data[0].chat_start_time,
                  end_time: response.data[0].end_time,
                  user: response.data[0].user_email});
        return response;
    }catch (error){
      dispatch(setChatResponseError(error.message));
      console.log(error);
      return error;
    }
  }

  const saveChat = async({userMessage, botMessage, chatName, firstCall, message_id, start_time, end_time, user }) =>{
    const token = await initialize();
    try{
      const request = generateSaveChatRequest(userMessage, botMessage, chatName, message_id, start_time, end_time, user);
      const response = await axios.post(baseUrl+"api/savechat", request,
                    {headers:{
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json',
                    }}
      );
      if(response.data.status){
        if(firstCall){
          let newChatList = [];
          if(chatList.length > 0){
              chatList.map((item) => {
                newChatList.push(item);
              });
          }
          newChatList.unshift({"chat_name": chatName, "chat_id":request.chat_id});
          dispatch(setChatListSuccess(newChatList));
        }
      }
      dispatch(setChatResponseSave(true));
    }catch(error){
      console.log(error.message);
    }
  }

  const getEmbeddings = async(selected, isRefreshCall) => {
    // console.log(selected);
    if(selected.label !== "Loading20your%20teams..." && selected.label !== "Loading your teams...")
    {
      const token = await initialize();
      try{
        const response = await axios.get(baseUrl+`api/teamsessions/${selected.value}/sessions`,
                                {headers:{
                                  'Authorization': 'Bearer ' + token,
                                  'Content-Type': 'application/json',
                              }}
        );
        dispatch(setEmbeddings(response.data, isRefreshCall));
      }catch (error){
        console.log(error.message);
      }
    }
  } 

  const getSessionFiles = async() => {
      const token = await initialize();
      try{
        const response = await axios.get(baseUrl+`api/sessionfiles/session/${currentSelectedEmbedding.value}/files`,
                                {headers:{
                                  'Authorization': 'Bearer ' + token,
                                  'Content-Type': 'application/json',
                              }}
        );
        dispatch(setSessionFiles(response.data));
      }catch (error){
        console.log(error.message);
      }
  }
  
  const getChatList = async() => {
    dispatch(setChatListLoading(true));
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+`api/chatlist/teamid/${selectedTeam.value}/session/${currentSelectedEmbedding.value}/chats`,
                              {headers:{
                                'Authorization': 'Bearer ' + token,
                                'Content-Type': 'application/json',
                            }}
      );
      if(response.data !== null)
        dispatch(setChatListSuccess(response.data));
      else
      dispatch(setChatListFailure("No Chats available!"));
    }catch(error){
      dispatch(setChatListFailure(error.message));
    }
  }

  const getChatHistory = async({chatId}) => {
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+`api/chathistory/${chatId}/messages`,
                                {headers:{
                                  'Authorization': 'Bearer ' + token,
                                  'Content-Type': 'application/json',
                                }
                              });
      const responseWithoutGreeting = response.data.slice(1); // Removes the first element
      dispatch(setChatContent(responseWithoutGreeting, chatId));
      return response;
    }catch (error){
      return error.message;
    }
  }

  const checkSessionName = async({sessionName})=>{
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+`api/teamsessions/teamid/${selectedCaseCloud.id}/session_name/${sessionName}/duplicatecheck`,
            {headers:{
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
          }});
        return response;
    }catch (error){

    }
  }

  const deleteEmbedding = async({sessionId})=>{
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+`api/teamsessions/teamid/${selectedCaseCloud.id}/sessionid/${sessionId}/delete`,
            {headers:{
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
          }});
        return response;
    }catch (error){

    }
  }

  const deleteChat = async({chatId}) => {
    const token = await initialize();
    try{
      const response = await axios.delete(baseUrl+`api/chat/team_id/${selectedTeam.value}/session_id/${currentSelectedEmbedding.value}/chat_id/${chatId}/delete`,
                                {headers:{
                                  'Authorization': 'Bearer ' + token,
                                  'Content-Type': 'application/json',
                                }
                              });
      dispatch(refreshChatDetails());
      return response;
    }catch (error){
      return error.message;
    }
  }

  const getOpenAIParams = async() =>{
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+'api/openai_params', {headers:{
                              'Authorization': 'Bearer ' + token,
                              'Content-Type': 'application/json',
                            }
                          });
      // console.log("in openAI call");
      // console.log(response.data);
      dispatch(setAllOpenAiParameters(response.data));
    }catch (error){
      console.log(error.message);
    }
  }

  const getEmbeddingParams = async() =>{
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+'api/embedding_params', {headers:{
                              'Authorization': 'Bearer ' + token,
                              'Content-Type': 'application/json',
                            }
                          });
      dispatch(setAllChunkingSettings(response.data));
    }catch (error){
      console.log(error.message);
    }
  }

  const deleteSession = async({teamId, sessionId}) =>{
    const token = await initialize();
    try{
      const response = await axios.delete(baseUrl+`api/teamsessions/teamid/${teamId}/sessionid/${sessionId}/delete`, {headers:{
                              'Authorization': 'Bearer ' + token,
                              'Content-Type': 'application/json',
                            }
                          });
      return response;
    }catch (error){
      console.log(error.message);
    }
  }

  const getNotification = async({teamId, sessionId}) => {
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+`api/session_notification/teamid/${teamId}/sessionid/${sessionId}/state`,
                      {headers: {
                        'Authorization': 'Bearer '+ token,
                        'Content-Type': 'application/json'
                      }
                    });
            return response;
    }catch (error){

    }
  }

  const saveFeedback = async({messageId, rating, feedback}) => {
    const token = await initialize();
    try{
      const request = {
        "team_id": selectedTeam.value,
        "chat_id": currentChatId,
        "session_id": currentSelectedEmbedding.value,
        "message_id": messageId,
        "rating": rating,
        "feedback": feedback
      }
      const response = await axios.post(baseUrl+`api/chatfeedback`, request,
                      {headers: {
                        'Authorization': 'Bearer '+ token,
                        // 'responseType': 'blob',
                        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      }
                    });
            return response;
    }catch (error){

    }
  }

  const getCTKAEmbedKPIs = async() => {
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+'api/embedding_kpi',
                              {headers:
                                {
                                  'Authorization': 'Bearer '+token,
                                  'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                }
                            });
      return response;
    }catch (error){
      console.log(error.message);
    }
  }

  const getCTKAChatKPIs = async() => {
    const token = await initialize();
    try{
      const response = await axios.get(baseUrl+'api/chat_response_kpi',
                              {headers:
                                {
                                  'Authorization': 'Bearer '+token,
                                  'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                }
                            });
      return response;
    }catch (error){
      console.log(error.message);
    }
  }

  return {
    headers,
    loading,
    initialize,
    getTeams,
    getGroupRecords,
    getTeamFiles,
    generateEmbeddings,
    getChatResponse,
    getEmbeddings,
    getSessionFiles,
    getTeamFilesDetails,
    getChatList,
    getChatHistory,
    checkSessionName,
    deleteEmbedding,
    deleteChat,
    getOpenAIParams,
    getEmbeddingParams,
    deleteSession,
    getNotification,
    saveFeedback,
    getUserAccess,
    getCTKAEmbedKPIs,
    getCTKAChatKPIs
  };
};

export default EmbeddingsApi;