import * as actionTypes from "../action-types.js";

const initialState = {
  notifications: [],
  notificationResponse: null,
  remainingFiles: null,
};

const notificationReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.LOAD_NOTIFICATION:
            const localNotifs = localStorage.getItem("notifications");
            let newNotifs = [];
            if(localNotifs !== undefined && localNotifs !== null){
                newNotifs = JSON.parse(localNotifs);
            }
            return {
                notifications: newNotifs
            };
        case actionTypes.SET_NEW_NOTIFICATION:
            const newNotif = {"is_processed": false,
                                "is_error": false,
                                "sessionId": action.payload.sessionId,
                                "teamId": action.payload.teamId,
                                "sessionName": action.payload.sessionName,
                                "notifTime": Date.now(),
                                "error": ""};
            const prevNotifs = state.notifications !== null
                            && state.notifications !== undefined ? state.notifications : [];
            let updatedNewNotifs = [...prevNotifs, newNotif];
            const updatedNewLocalNotifs = JSON.stringify(updatedNewNotifs);
            localStorage.setItem("notifications", updatedNewLocalNotifs);
            return {
                notifications: updatedNewNotifs
            }
        case actionTypes.REMOVE_NOTIFICATION:
            const updatedNotifs = state.notifications.filter((item)=>
                item.sessionId !== action.payload
            );
            const updatedLocalNotifs = JSON.stringify(updatedNotifs);
            localStorage.setItem("notifications", updatedLocalNotifs);
            return {
                notifications: updatedNotifs
            }
        // case actionTypes.SET_NOTIFICATION_RESPONSE:
        //     let remCount = 0;
        //     try{
        //         let processedCount = 0;
        //         action.payload.map((item) => {
        //             if(item.embedding_created === false || item.embedding_created === true){
        //                 processedCount = processedCount+1;
        //             }
        //         });
        //         remCount = processedCount;
        //     }catch (error){
        //         console.log("caught error");
        //         remCount = 0;
        //     }
        //     return {
        //         ...state,
        //         notificationResponse: action.payload,
        //         remainingFiles: remCount,
        //     }
        default:
            return state;
    }
}

export default notificationReducer;
