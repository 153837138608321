export const SET_TEAM_LIST_SUCCESS = "SET_TEAM_LIST_SUCCESS";
export const SET_TEAM_LIST_FAIL = "SET_TEAM_LIST_FAIL";
export const SET_SELECTED_TEAM = "SET_SELECTED_TEAM";
export const SET_TEAM_GROUP_RECORDS_SUCCESS = "SET_TEAM_GROUP_RECORDS_SUCCESS";
export const SET_TEAM_GROUP_RECORDS_FAIL = "SET_TEAM_GROUP_RECORDS_FAIL";
export const SET_TEAM_GROUP_LOADING_AND_ERROR = "SET_TEAM_GROUP_LOADING_AND_ERROR";
export const SET_TEAM_FILES_FOLDERS_SUCCESS = "SET_TEAM_FILES_FOLDERS_SUCCESS";
export const SET_TEAM_FILES_FOLDERS_FAIL = "SET_TEAM_FILES_FOLDERS_FAIL";
export const SET_SELECTED_FILES = "SET_SELECTED_FILES";
export const CLEAR_SELECTED_FILES = "CLEAR_SELECTED_FILES";
export const SET_CASE_CLOUD_NAME = "SET_CASE_CLOUD_NAME";
export const SET_SESSION_NAME = "SET_SESSION_NAME";
export const SET_CHAT_CONTENT = "SET_CHAT_CONTENT";
export const SET_CHAT_RESPONSE = "SET_CHAT_RESPONSE";
export const SET_CHAT_ID = "SET_CHAT_ID";
export const SET_CHAT_NAME = "SET_CHAT_NAME";
export const SET_SELECTED_EMBEDDING = "SET_SELECTED_EMBEDDING";
export const SET_EMBEDDINGS = "SET_EMBEDDINGS";
export const SET_SESSION_FILES = "SET_SESSION_FILES";
export const SET_CHAT_LIST_SUCCESS = "SET_CHAT_LIST_SUCCESS";
export const SET_CHAT_LIST_FAIL = "SET_CHAT_LIST_FAIL";
export const SET_CHAT_LIST_LOADING = "SET_CHAT_LIST_LOADING";
export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";
export const SET_GLOBAL_WARNING = "SET_GLOBAL_WARNING";
export const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";
export const SET_GLOBAL_SUCCESS = "SET_GLOBAL_SUCCESS";
export const GLOBAL_ERROR = "ERROR";
export const GLOBAL_WARNING = "WARNING";
export const GLOBAL_LOADING = "LOADING";
export const GLOBAL_SUCCESS = "SUCCESS";
export const REFRESH_CHAT_DETAILS = "REFRESH_CHAT_DETAILS";
export const SET_FILE_SESSION_DETAIL = "SET_FILE_SESSION_DETAIL";

// OPEN AI PARAMS
export const SET_ALL_OPENAI_PARAMETERS = "SET_ALL_OPENAI_PARAMETERS";
export const SET_TEMPERATURE = "SET_TEMPERATURE";
export const SET_MAX_P = "SET_MAX_P";
export const SET_MAX_TOKENS = "SET_MAX_TOKENS";
export const SET_FREQUENCY_PENALTY = "SET_FREQUENCY_PENALTY";
export const SET_PRESENCE_PENALTY = "SET_PRESENCE_PENALTY";
export const SET_CHUNK_SEARCH_SIZE = "SET_CHUNK_SEARCH_SIZE";

// CHUNKING SETTINGS
export const SET_ALL_CHUNKING_SETTINGS = "SET_ALL_CHUNKING_SETTINGS";
export const SET_PPTX_CHUNKING_STRATEGY = "SET_PPTX_CHUNKING_STRATEGY";
export const SET_DOCX_CHUNKING_STRATEGY = "SET_DOCX_CHUNKING_STRATEGY";
export const SET_XLSX_CHUNKING_STRATEGY = "SET_XLSX_CHUNKING_STRATEGY";
export const SET_PDF_CHUNKING_STRATEGY = "SET_PDF_CHUNKING_STRATEGY";
export const SET_CSV_CHUNKING_STRATEGY = "SET_CSV_CHUNKING_STRATEGY";
export const SET_CHUNK_SIZE = "SET_CHUNK_SIZE";
export const SET_CHUNK_OVERLAP = "SET_CHUNK_OVERLAP";
export const SET_EMBEDDING_MODEL = "SET_EMBEDDING_MODEL";
export const SET_ANONYMIZE_CHUNK = "SET_ANONYMIZE_CHUNK";
export const SET_TREAT_TABLES_SEPARATELY = "SET_TREAT_TABLES_SEPARATELY";

export const SET_CLEAR_CURRENT_SESSION = "SET_CLEAR_CURRENT_SESSION";
export const SET_NAVIGATED = "SET_NAVIGATED";
export const SET_SESSION_DROP_DOWN_OPTIONS = "SET_SESSION_DROP_DOWN_OPTIONS";
export const SET_TEAM_DROP_DOWN_OPTIONS = "SET_TEAM_DROP_DOWN_OPTIONS";

// Notifications
export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const LOAD_NOTIFICATION = "LOAD_NOTIFICATION";
export const SET_NOTIFICATION_RESPONSE = "SET_NOTIFICATION_RESPONSE";

export const SET_SELECT_ALL_CLICKED = "SET_SELECT_ALL_CLICKED";

// Chat Actions
export const SET_RESPONSE_LOADING = "SET_RESPONSE_LOADING";
export const SET_CHAT_SAVE = "SET_CHAT_SAVE";
export const SET_RESPONSE_ERROR = "SET_RESPONSE_ERROR";

export const SET_DISMISS_TOAST = "SET_DISMISS_TOAST";