// store.js
import { applyMiddleware, createStore } from "redux";

import rootReducer from './state/action-reducers/root-reducer.js';
import thunk from "redux-thunk";

//rootReducer - Pick all reducers
//{} - set initial state which will be blank
//Apply Middleware thunk- No thunk is helpful to trigger async function
const store = createStore(rootReducer, {}, applyMiddleware(thunk)); // Create the Redux store

export default store;
