import React from 'react';

const MessageParser = ({ children, actions}) =>{

  const parse = (message) => {
    const lowerCase = message.toLowerCase().trim();

    const newPrompt = [
      {
        chat_id: "",
        query: lowerCase,
      },
    ];

    // var messageHistory = JSON.parse(localStorage.getItem("messageHistory")) || [];

    // messageHistory = [...messageHistory, ...newPrompt];
    // localStorage.setItem('messageHistory', JSON.stringify(messageHistory));

    return actions.handleUserMessage(lowerCase);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {},
        });
      })}
    </div>
  );
}

export default MessageParser;
