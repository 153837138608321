import { combineReducers } from "redux";
import teamGroupReducer from "./teamGroups-reducer";
import teamListReducer from "./teamList-reducer";
import teamFilesReducer from "./teamfiles-reducer";
import selectedFilesReducer from "./selectedfiles-reducer";
import chatReducer from "./chat-reducer";
import embeddingReducer from "./embedding-reducer";
import sessionFileReducer from "./session-files-reducer";
import chatListReducer from "./chat-list-reducer";
import globalReducer from "./global-reducer";
import openAIParameterReducer from "./openAI-parameter-reducer";
import chunkingSettingsReducer from "./chunking-settings-reducer";
import fileSessionDetailReducer from "./file-session-detail-reducer";
import navigationReducer from "./navigation-reducer";
import notificationReducer from "./notification-reducer";
import chatLoadingReducer from "./chat-loading-reducer";

const rootReducer = combineReducers({
  team_list: teamListReducer,
  team_groups: teamGroupReducer,
  team_files: teamFilesReducer,
  selected_files: selectedFilesReducer,
  chat_details: chatReducer,
  chatlist: chatListReducer,
  embedding_details: embeddingReducer,
  session_file_details: sessionFileReducer,
  global: globalReducer,
  openAI: openAIParameterReducer,
  chunkingSettings: chunkingSettingsReducer,
  file_session_details: fileSessionDetailReducer,
  navigation: navigationReducer,
  notification: notificationReducer,
  chatLoading: chatLoadingReducer
  // other reducers...
});

export default rootReducer;
