import * as actionTypes from "../action-types.js";

const initialState = {
};

const sessionFileReducer = (state= initialState, action) => {

    switch(action.type){
        case actionTypes.SET_SESSION_FILES:
            return {
                ...state,
                details: action.payload.data,
              }
      default:
        return state;
    }
}

export default sessionFileReducer;
