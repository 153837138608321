import * as actionType from "../action-types.js";

const setTeamsSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionType.SET_TEAM_LIST_SUCCESS,
      payload: data
    });
  };
};

const setTeamsFail = (error) => {
    return (dispatch) => {
      dispatch({
        type: actionType.SET_TEAM_LIST_FAIL,
        error: error
      });
    };
  };

  const setSelectedTeam = (data) => {
    return (dispatch) => {
      dispatch({
        type: actionType.SET_SELECTED_TEAM,
        payload: data
      });
    };
  }

  const setTeamDropDrownOptions = (data) => {
    return (dispatch) => {
      dispatch({
        type: actionType.SET_TEAM_DROP_DOWN_OPTIONS,
        payload: data
      });
    };
  }
  export { setTeamsFail, setTeamsSuccess, setSelectedTeam, setTeamDropDrownOptions };