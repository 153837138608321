import * as actionType from "../action-types.js";

const setAllChunkingSettings = (data) => {
    return (dispatch) =>{
        dispatch({
            type: actionType.SET_ALL_CHUNKING_SETTINGS,
            payload: data,
        });
    };  
}

const setPPTXStrategy = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_PPTX_CHUNKING_STRATEGY,
            payload: data,
        });
    };
};

const setPDFStrategy = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_PDF_CHUNKING_STRATEGY,
            payload: data,
        });
    };
};

const setDOCXStrategy = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_DOCX_CHUNKING_STRATEGY,
            payload: data,
        });
    };
};

const setXLSXStrategy = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_XLSX_CHUNKING_STRATEGY,
            payload: data,
        });
    };
};

const setCSVStrategy = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CSV_CHUNKING_STRATEGY,
            payload: data,
        });
    };
};

const setEmbeddingModel = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_EMBEDDING_MODEL,
            payload: data
        });
    }
}

const setChunkSize = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHUNK_SIZE,
            payload: data,
        });
    };
};

const setChunkOverlap = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHUNK_OVERLAP,
            payload: data
        });
    }
}

const setAnonymizeChunk = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_ANONYMIZE_CHUNK,
            payload: data,
        });
    };
};

const setTreatTableSeparately = (data) => {
    return (dispatch) => {
        dispatch ({
            type: actionType.SET_TREAT_TABLES_SEPARATELY,
            payload: data
        });
    };
}

export { setAllChunkingSettings, setAnonymizeChunk, setCSVStrategy,setDOCXStrategy, setEmbeddingModel, setPDFStrategy,
        setPPTXStrategy, setXLSXStrategy, setChunkSize, setChunkOverlap, setTreatTableSeparately };

