import React, { useState } from 'react';
import './team-component.css'; // You can create a CSS file for styling
import {AiOutlineCloudServer} from 'react-icons/ai';
import EmbeddingsApi from "../../../api-client/api-embeddings.js";
import { useDispatch, useSelector } from 'react-redux';
import { removeSelectedFiles} from '../../../state/action-creators/selectedfiles-action';
import LoadingComponent from '../../loading/loading.jsx';

const TeamComponent = ({ text, itemId }) => {

  const embeddingsApi = EmbeddingsApi();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedCaseCloud = useSelector((state) => state.selected_files.selectedCaseCloud);

  const onTeamCick = async () =>{
    setLoading(true);
    dispatch(removeSelectedFiles({"name":text, "id": itemId}));
    await embeddingsApi.getGroupRecords({teamId: itemId, caseCloudName: text});
    await embeddingsApi.getTeamFilesDetails({teamId: itemId});
    setLoading(false);
  }

  return (
    <li
      className={itemId === selectedCaseCloud.id ? "team-row-component active":'team-row-component'}
      onClick={onTeamCick}
    >
        <div className='team-options'>
            <AiOutlineCloudServer/>
        </div>
      <span className={itemId === selectedCaseCloud.id ? "team-chat-text-field active":'team-chat-text-field' }>{text}</span>
      {loading  ? <LoadingComponent loading={loading} /> : null}
    </li>
  );
};

export default TeamComponent;