import * as actionType from "../action-types.js";

const setSessionFiles = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_SESSION_FILES,
            payload: {data}
        });
    };
};

export { setSessionFiles };

