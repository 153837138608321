import * as actionType from "../action-types.js";

const setGlobalError = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_GLOBAL_ERROR,
            payload: data
        });
    };
};

const setGlobalWarning = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_GLOBAL_WARNING,
            payload: data
        });
    }
}

const setGlobalLoading = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_GLOBAL_LOADING,
            payload: data
        });
    }
}

const setGlobalSuccess = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_GLOBAL_SUCCESS,
            payload: data
        })
    }
}

const setDismissToast = () => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_DISMISS_TOAST,
        });
    }
}

export { setGlobalError, setGlobalWarning, setGlobalLoading, setGlobalSuccess, setDismissToast };

