import React, { useState } from 'react';
import './file-component.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectAllClicked, setSelectedFilesAndFolders } from '../../../state/action-creators/selectedfiles-action';
import { Link } from '@bain/design-system';
import SessionTooltipButton from '../session-tooltip/session-tooltip.jsx'
const FileDetailsComponent = ({items, handleParentFileClick}) => {

    const allItems = items;
    const dispatch = useDispatch();
    const selectedFiles = useSelector((state) => state.selected_files.selectedFiles);
    const allChecked = useSelector((state) => state.selected_files.selectAllClicked);
    const onCheckBoxClick = (event, item) =>{
        if(event.target.checked === false){
            dispatch(setSelectAllClicked(false));
        }else{
            const onlyFiles = allItems.filter(item => 
                (getExtension(item) === "pdf" || 
                getExtension(item) === "xlsx" || 
                // getExtension(item) === "xls" || 
                // getExtension(item) === "doc" || 
                getExtension(item) === "docx" || 
                // getExtension(item) === "ppt" || 
                getExtension(item) === "pptx" || 
                getExtension(item) === "csv") && !item.hasOwnProperty("folder")
            );
            if(selectedFiles.length+1 === onlyFiles.length){
                dispatch(setSelectAllClicked(true));
            }
        }
        dispatch(setSelectedFilesAndFolders(item, event.target.checked));
    }

    const getImageURL = (item) =>{
        let extension = item.name.split('.').pop().toLowerCase()
        if(extension === "doc")
        {
            extension = "docx"
        }
        if(extension === "ppt")
        {
            extension = "pptx"
        }
        if(extension === "xls")
        {
            extension = "xlsx"
        }
        let url = `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230223.001/assets/item-types/20/${extension}.svg`
        return url;
    }
    const getExtension = (item) =>{
        let extension = item.name.split('.').pop().toLowerCase()
        return extension;
    }
    const convertBytesToSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
      };

    const filteredItems = allItems.filter(item => 
        getExtension(item) === "pdf" || 
        getExtension(item) === "xlsx" || 
        // getExtension(item) === "xls" || 
        // getExtension(item) === "doc" || 
        getExtension(item) === "docx" || 
        // getExtension(item) === "ppt" || 
        getExtension(item) === "pptx" || 
        getExtension(item) === "csv" || item.hasOwnProperty("folder")
    ) 

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const currentYear = new Date().getFullYear();

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        let modifiedDate = `${month.substring(0,3)} ${day}, ${year}`
        if(year === currentYear){
            modifiedDate = `${month.substring(0,3)} ${day}`
        }
        return modifiedDate;
    }

    const onSelectAll = (event) => {
        const checked = event.target.checked;
        dispatch(setSelectAllClicked(checked));
        filteredItems.map((item) => {
            if(!item.hasOwnProperty("folder"))
                dispatch(setSelectedFilesAndFolders(item, checked));
        });
    }

    const checkIfSelected = (itemId) => {
        let selected = false;
        selectedFiles.map((item)=>{
            if(item.id === itemId)
                selected = true;
        });
        return selected;
    }

    const folderClick = (item) => {
        dispatch(setSelectAllClicked(false));
        handleParentFileClick(item);
    }

    const onlyFiles = allItems.filter(item => 
        (getExtension(item) === "pdf" || 
        getExtension(item) === "xlsx" || 
        // getExtension(item) === "xls" || 
        // getExtension(item) === "doc" || 
        getExtension(item) === "docx" || 
        // getExtension(item) === "ppt" || 
        getExtension(item) === "pptx" || 
        getExtension(item) === "csv") && !item.hasOwnProperty("folder")
    );

    return (
        <div>
            <table className="data-table file-table-header">
                <tbody>
                    <tr className='header'>
                        <th className='name'>
                            {onlyFiles.length > 0 ?<input style={{marginLeft: "-48px", marginRight: "40px"}}
                                    type="checkbox" 
                                    onChange={(event)=>onSelectAll(event)}
                                    checked={allChecked}/>
                                    :<div/>
                            }
                            Name
                        </th>
                        <th className='modified'>Modified</th>
                        <th className='size'>Size</th>
                        <th className='info'>Info</th>
                    </tr>
                    { filteredItems.length > 0
                        ? filteredItems.map((item, index)=>(
                        <tr key={item.name+`${index}`} onDoubleClick={()=>{folderClick(item)}}>
                            <td>
                                <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                                    <label style={{padding: '8px 0 8px 0', verticalAlign: 'middle'}}>
                                    {item.hasOwnProperty("folder") 
                                                ? <div/>
                                                : <input type="checkbox" 
                                                    onChange={(event)=>onCheckBoxClick(event, item)}
                                                    checked={checkIfSelected(item.id)}
                                                  />}                  
                                    </label>
                                    <div> 
                                        {item.hasOwnProperty("folder")
                                            ? <img src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230223.001/assets/item-types/20/folder.svg" className="nameCellIcon_7b2ea6301" alt="Folder"></img>
                                            : <img src={getImageURL(item)} alt={getExtension(item)} className="nameCellIcon_7b2ea6301"></img> 
                                        }
                                            
                                    </div>
                                    <div style={{padding: '8px 0 8px 0', verticalAlign: 'middle'}}>
                                        <Link style={{verticalAlign: "center"}} onClick={()=>{folderClick(item)}}>{item.name}</Link>
                                    </div>
                                </div>
                            </td>
                            <td className='modifiedtext'><div className='modified'>{formatDate(item.lastModifiedDateTime)}</div><div className='modifiedBy'>By {item.lastModifiedBy.user.displayName}</div></td>
                            <td className='sizetext'>{item.hasOwnProperty("folder")? "" : convertBytesToSize(item.size)}</td>
                            <td>
                                <SessionTooltipButton id={item.id} />
                            </td>
                        </tr>
                        ))
                        : <div style={{marginLeft: "55px", marginTop: "20px"}}>No Supported Files and Folders present</div>
                    }
                </tbody>
            </table>
        </div>
    );
};

export default FileDetailsComponent;
