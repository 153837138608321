import * as actionTypes from "../action-types.js";

const initialState = {
  global_error: "",
  global_warning: "",
  global_loading: "",
  global_message: {message: "", type: ""}
};

const globalReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_GLOBAL_ERROR:
            return {
                ...state,
                global_error: action.payload,
                global_message: {message: action.payload, type: actionTypes.GLOBAL_ERROR}
            }
        case actionTypes.SET_GLOBAL_WARNING:
            return {
                ...state,
                global_warning: action.payload,
                global_message: {message: action.payload, type: actionTypes.GLOBAL_WARNING}
            }
        case actionTypes.SET_GLOBAL_LOADING:
            return{
                ...state,
                global_loading: action.payload,
                global_message: {message: action.payload, type: actionTypes.GLOBAL_LOADING}
            }
        case actionTypes.SET_GLOBAL_SUCCESS:
            return {
                ...state,
                global_message: {message: action.payload, type: actionTypes.GLOBAL_SUCCESS}
            }
        case actionTypes.SET_DISMISS_TOAST:
            return{
                global_error: "",
                global_loading: "",
                global_warning: "",
                global_message: {message: "", type: ""}
            }
        default:
            return state;
    }
}

export default globalReducer;
