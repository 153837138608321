import React from "react";
import BotIcon from '../assets/bot.png';

const BotAvatar = () => {

  return (
    <div className="react-chatbot-kit-chat-bot-avatar-container">
        {<img src={BotIcon} className="BotIcon" alt="Chatbot avatar" />}
    </div>
  );
};

export default BotAvatar;