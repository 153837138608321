import React, { useState } from 'react';
import './navigation-button.css';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setTeamRecordsLoadingError } from '../../state/action-creators/teamGroups-actions';
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';
import { clearCurrentSession } from '../../state/action-creators/selectedfiles-action';
import { setNavigated } from '../../state/action-creators/navigation-action';

const NavigationButton = ({ text, path, type, handleActionClick, disabled }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleButtonClick =(path)=>{
    if(type === 'navigate')
    {
      dispatch(setTeamRecordsLoadingError(true, null));
      dispatch(clearCurrentSession());
      if(path === '') dispatch(setNavigated(true));
      navigate('/'+path);
    }
    else{
      if(disabled !== true)
        handleActionClick();
    }
  }

  return (
    <div
      className={disabled ?`column-component-disabled` : type===navigate ? `column-component` : `column-component-not-navigate`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={()=>{handleButtonClick(path)}}
    >
      {type === 'navigate' && path === ''
                  ? <MdKeyboardArrowLeft style={{alignSelf: "start", marginRight: "15px"}}/>
                  : null}
      {type === 'navigate' && path === 'chat'
                  ? <div style={{width: "50px"}}/>
                  : null}
      <div className='navigation-text-field'>{text}</div>
      {type === 'navigate' && path === 'chat'
                  ? <MdKeyboardArrowRight style={{alignSelf: "end", marginLeft: "35px"}}/>
                  : null}
    </div>
  );
};

export default NavigationButton;
