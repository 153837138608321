import React, { useEffect, useState } from 'react';
import CaseTeamClouds from '../team-channels/team-list/team-list.jsx';
import { useSelector } from 'react-redux';
import './file-panel.css';

const FilePanelComponent = ({embeddingsApi}) => {

    const teams = useSelector((state) => state.team_list.teamList);
    const teamsError = useSelector((state) => state.team_list.error);
    const teamsLoading = useSelector((state) => state.team_list.loading);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(()=>{
        setFilteredData(teams);
    },[teams]);

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        if(newSearchTerm === ""){
            setFilteredData(teams);
        }else{
            const prevTeams = teams ?? [];
            const newFilteredData = prevTeams.filter((item) =>
                item.displayName.toLowerCase().includes(newSearchTerm.toLowerCase())
            );
            setFilteredData(newFilteredData);
        }
    };

  return (
    <div className="file-explorer-column-component">
        <div className='your-team-view'>
            <span className='header-field'>Your Teams Case Cloud</span>
        </div>
        <input
            className="react-chatbot-kit-chat-input session-name"
            type="text"
            onChange={handleSearchChange}
            placeholder="Search"
        />
        <div className='dir-list-container'>
            {teamsLoading 
                        ? <div style={{paddingLeft: "90px", paddingTop: "20px"}}>Loading your teams...</div> 
                        : teamsError != null
                            ? <div>{teamsError.message}</div>
                            : <CaseTeamClouds caseClouds={filteredData}></CaseTeamClouds>}
        </div>
  </div>
  );
};

export default FilePanelComponent;
