import * as actionType from "../action-types.js";

const setFileSessionDetail = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_FILE_SESSION_DETAIL,
            payload: data
        });
    }
}

export { setFileSessionDetail};

