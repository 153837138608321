import { useState } from 'react';
import './error-component.css';
import {RxCross2} from 'react-icons/rx';

const ErrorComponent = ({error}) =>{

    // const [error, setError] = useState(propError);

    // const hideError= () =>{
    //     setError(null)
    // }

    return (
        <>
            <div className='error'>
                <div className='cross'>
                    <RxCross2/>
                </div>
                <div className='error-message'>
                    {error}
                </div>
            </div>
        </>
    );

}

export default ErrorComponent;