import * as actionTypes from "../action-types.js";

const initialState = {
  embeddings: [
    { value: 'loadingsessions', label: 'Loading your sessions..' }
  ],
  selectedEmbedding: { value: 'loadingsessions', label: 'Loading your sessions..' },
  sessionDropDownOptions: [{ value: 'loadingsessions', label: 'Loading your sessions..' }]
};

const noSessions= { value: 'loadingsessions', label: 'No sessions present' }
const noSessionList = [{session_id: 'loadingsessions', session_name: 'No sessions present', index_name: 'loadingsessions'}];
const embeddingReducer = (state= initialState, action) => {

    switch(action.type){
        case actionTypes.SET_EMBEDDINGS:
            let removedProcessingSessions = []
            if(action.payload.data !== null)
                removedProcessingSessions = action.payload.data.filter((item)=>
                        item.is_processed !== false
                    );
            if(removedProcessingSessions!== undefined && removedProcessingSessions !== null && removedProcessingSessions.length !== 0)
            {
                const newSelected = {value: removedProcessingSessions[0].session_id, 
                    label: removedProcessingSessions[0].session_name, 
                    index: removedProcessingSessions[0].index_name, 
                    files: removedProcessingSessions[0].file_details, 
                    createddate: removedProcessingSessions[0].created_date, 
                    createdby: removedProcessingSessions[0].created_by};
                    // console.log(newSelected);
                if(action.payload.isRefreshCall){
                    const selectedEmpty = state.selectedEmbedding.value === 'loadingsessions';
                    return {
                        ...state,
                        embeddings: removedProcessingSessions,
                        selectedEmbedding: selectedEmpty ? newSelected : state.selectedEmbedding
                    }
                }else{
                    return {
                        ...state,
                        embeddings: removedProcessingSessions,
                        selectedEmbedding: newSelected
                    }
                }
            }
            else
            {
                return {
                    ...state,
                    embeddings: noSessionList,
                    selectedEmbedding: noSessions
                }
            }
        case actionTypes.SET_SELECTED_EMBEDDING:
            return {
                ...state,
                selectedEmbedding: action.payload
            }
        case actionTypes.SET_SESSION_DROP_DOWN_OPTIONS:
            return {
                ...state,
                sessionDropDownOptions: action.payload
            }
      default:
        return state;
    }
}

export default embeddingReducer;
