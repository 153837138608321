import * as actionType from "../action-types.js";

const setNavigated = (navigated) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_NAVIGATED,
            payload: navigated
        });
    };
};

export { setNavigated };