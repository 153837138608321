import * as actionType from "../action-types.js";

const setSelectedFilesAndFolders = (item, checked) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_SELECTED_FILES,
            payload: {checked, item},
        });
    };
};

const removeSelectedFiles = (newCaseCloud) => {
    return (dispatch) => {
        dispatch({
            type: actionType.CLEAR_SELECTED_FILES,
            payload: newCaseCloud,
        });
    }
}

const setSession = (session) =>{
    // console.log(session)
    return (dispatch) => {
        dispatch({
            type: actionType.SET_SESSION_NAME,
            payload: session
        });
    }
}

const clearCurrentSession = () => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CLEAR_CURRENT_SESSION,
        });
    };
}

const setSelectAllClicked = (checked) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_SELECT_ALL_CLICKED,
            payload: checked
        });
    }
}

export {setSelectedFilesAndFolders, removeSelectedFiles, setSession, clearCurrentSession, setSelectAllClicked};
