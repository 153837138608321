import * as actionType from "../action-types.js";

const setAllOpenAiParameters = (data) => {
    return (dispatch) =>{
        dispatch({
            type: actionType.SET_ALL_OPENAI_PARAMETERS,
            payload: data,
        });
    };  
}

const setTemperature = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_TEMPERATURE,
            payload: data,
        });
    };
};

const setMaxP = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_MAX_P,
            payload: data
        });
    }
}

const setMaxTokens = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_MAX_TOKENS,
            payload: data,
        });
    };
};

const setFrequencyPenalty = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_FREQUENCY_PENALTY,
            payload: data
        });
    }
}

const setPresencePenalty = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_PRESENCE_PENALTY,
            payload: data,
        });
    };
};

const setChunkSearchSize = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHUNK_SEARCH_SIZE,
            payload: data
        });
    }
}

export { setAllOpenAiParameters, setTemperature, setMaxP, setMaxTokens, setFrequencyPenalty, setPresencePenalty, setChunkSearchSize };

