import React, { useEffect, useState } from 'react';
import './chat-history-column.css'; // You can create a CSS file for styling
import RowComponent from '../chat-history/chat-history';
import {MdAddCircleOutline, MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import DropdownWithSearch from '../dropdown/dropdown-component';
import EmbeddingsApi from '../../api-client/api-embeddings';
import { useDispatch, useSelector } from 'react-redux';
import { setChatContent } from '../../state/action-creators/chat-action';
import ShimmerList from '../shimmer/shimmer-list.jsx';
import { LuRefreshCw } from 'react-icons/lu';
import LoadingComponent from '../loading/loading';
import { setNavigated } from '../../state/action-creators/navigation-action';
import { Tooltip } from '@carbon/react';

const ChatColumnComponent = () => {

  const dispatch = useDispatch();
  const chatList = useSelector((state) => state.chatlist.chatList);
  const chatListLoading = useSelector((state) => state.chatlist.loading);
  const chatListError = useSelector((state) => state.chatlist.error);
  const teams = useSelector((state) => state.team_list.teamList);
  const selectedTeam = useSelector((state) => state.team_list.selectedTeam);
  const currentSelectedEmbedding = useSelector((state) => state.embedding_details.selectedEmbedding);
  const currentSessionFiles = useSelector((state) => state.session_file_details.details);
  const [collapsed, setCollapsed] = useState(true);
  // console.log(currentSessionFiles);
  const embeddingsApi = EmbeddingsApi();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getOpenAIParams(){
        await embeddingsApi.getOpenAIParams();
    }
    getOpenAIParams();
  }, []);

  useEffect(()=>{
    async function getTeams(){
      await embeddingsApi.getTeams();
    }
    if(teams === undefined || teams === null 
        || teams.length === 0 
        || teams[0].value === 'Loading your teams...')
      getTeams();
  },[]);

  const onNewChatClick = () =>{
    dispatch(setChatContent([], ""));
  }

  const refreshSessions = async() =>{
    dispatch(setNavigated(false));
    setLoading(true);
    await embeddingsApi.getEmbeddings(selectedTeam, true).then((response)=>{
      setLoading(false);
    });
  }

  const getImageURL = (item) =>{
    let extension = item.split('.').pop().toLowerCase()
    if(extension === "doc")
    {
        extension = "docx"
    }
    if(extension === "ppt")
    {
        extension = "pptx"
    }
    if(extension === "xls")
    {
        extension = "xlsx"
    }
    let url = `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230223.001/assets/item-types/20/${extension}.svg`
    return url;
}
const getExtension = (item) =>{
    let extension = item.split('.').pop().toLowerCase()
    return extension;
}
const getExpirationDate = () =>{
    const createddate = new Date(currentSelectedEmbedding.createddate);
    const expiry = new Date().setDate(createddate.getDate() + 30);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const expirydate = new Date(expiry).toLocaleDateString(undefined, options);
    return expirydate;
}

const onIconClick = () =>{
  setCollapsed(!collapsed);
}
  return (
    <div className="overview-column-component">
        <div className='session-overview'>
            <span className='new-chat-field'>Session Overview</span>
            {loading 
              ? <div style={{marginRight: "10px"}}><LoadingComponent loading={loading} height={"18"} width={"18"}/></div>
              : <LuRefreshCw style={{marginRight: "10px",cursor: "pointer", strokeWidth:"3"}} onClick={refreshSessions}/>
            }
        </div>
        <DropdownWithSearch/>
        <div className='new-chat' onClick={onNewChatClick}>
            <MdAddCircleOutline viewBox='0 0 22 22' style={{marginLeft: "10px"}}/>
            <span className='new-chat-field'>New Chat</span>
        </div>
        <div className='list-container'>
          {chatListLoading 
              ? <ShimmerList height={"50"} width={"300"}/>
              :chatListError
                ? <RowComponent key="chat_list_error" id={"error.."} name={"Some error occured!"}/>
                :chatList.map((chat)=>(
                  <RowComponent key={chat.chat_id} id={chat.chat_id} name={chat.chat_name}/>
                ))}      
        </div>
        <div className={collapsed ? 'session-detail-content-closed' :'session-detail-content'}>
          <div className='session-detail' onClick={onIconClick}>
            <span className='new-chat-field'>Session Details</span>
            {collapsed 
                ? <MdKeyboardArrowUp viewBox='0 0 24 24' style={{marginRight: "10px", height: "1.5em", width: "1.5em"}} onClick={onIconClick}/>
                : <MdKeyboardArrowDown viewBox='0 0 24 24' style={{marginRight: "10px", height: "1.5em", width: "1.5em"}} onClick={onIconClick}/>
            }
          </div>
          { collapsed
            ? <></>
            : <>
                <div className='header-view'>
                {currentSelectedEmbedding.createddate ?
                      <span className='chunking-header-field'>
                        {"Session Expiring on: " + getExpirationDate()}
                      </span>: ""
                    }
                  </div>
                  <div className='header-view'>
                    {currentSelectedEmbedding.createdby ?
                      <span style={{display: "block"}} className='chunking-header-field'>
                        {"Created by: " + currentSelectedEmbedding.createdby}
                      </span>:""
                    }
                  </div>
                  <div className='file-header-view'>
                    {currentSessionFiles?
                      currentSessionFiles.map((item, index) => (
                        <div key={index} className='file-list-field'>
                          <img src={getImageURL(item.file_name)} 
                              alt={getExtension(item.file_name)} 
                              className="nameCellIcon_7b2ea630" style={{width: "24px", verticalAlign:"center", marginRight: "5px"}}/>
                              {!item.is_processed?
                                <Tooltip title="Embedding creation failed for this file, find more details on file system page.">
                                  <span className="strike-through" style={{textOverflow: "ellipsis", overflow: 'hidden'}}>{item.file_name}</span>
                                </Tooltip>
                                :
                                <span style={{textOverflow: "ellipsis", overflow: 'hidden'}} title={item.file_name}>{item.file_name}</span>
                              }
                        </div>
                      ))
                      :
                      ""
                    }
                  </div>
              </>
          }
        </div>
  </div>
  );
};

export default ChatColumnComponent
