import { Shimmer } from "react-shimmer";
import './shimmer-list.css';

const ShimmerList = ({height, width}) => {

    const shimmerLength = [{},{},{},{}]
    const shimmerHeight = parseInt(height)-10;
    const shimmerWidth = parseInt(width);

    return (
        <>
            {
                shimmerLength.map((str,index)=>(
                    <div key={index} className="main-shimmer-content">
                        <Shimmer height={shimmerHeight} width={shimmerWidth}/>
                    </div>
                ))
            }
        </>
    );

}

export default ShimmerList;