import * as actionType from "../action-types.js";

const setEmbeddings = (data, isRefreshCall) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_EMBEDDINGS,
            payload: {data, isRefreshCall}
        });
    };
};

const setSelectedEmbedding = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_SELECTED_EMBEDDING,
            payload: data
        });
    }
}

const setSessionDropDownOptions = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_SESSION_DROP_DOWN_OPTIONS,
            payload: data
        })
    };
}

export { setEmbeddings, setSelectedEmbedding, setSessionDropDownOptions };

