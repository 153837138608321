import * as actionTypes from "../action-types.js";

const initialLoadingState = () => {
    return {"value":'loading', "label": 'loading'};
}

const initialState = {
  pptxStrategies: [initialLoadingState()],
  pdfStrategies: [initialLoadingState()],
  xlsxStrategies: [initialLoadingState()],
  csvStrategies: [initialLoadingState()],
  docxStrategies: [initialLoadingState()],
  embeddingModels: [initialLoadingState()],
  selectedpptxStrategy: initialLoadingState(),
  selectedpdfStrategy: initialLoadingState(),
  selectedxlsxStrategy: initialLoadingState(),
  selectedcsvStrategy: initialLoadingState(),
  selecteddocxStrategy: initialLoadingState(),
  selectedembeddingModel: initialLoadingState(),
  anonymizeChunks: true,
  chunkSize: 0,
  chunkOverlap: 0,
  minChunkSize: 0,
  maxChunkSize: 0,
  minChunkOverlap: 0,
  maxChunkOverlap: 0,
  treatTableSeparately: true,
};

const chunkingSettingsReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_ALL_CHUNKING_SETTINGS:
            let pptx = convertToDropdownFields(action.payload.pptx_chunking_strategy);
            let pdf = convertToDropdownFields(action.payload.pdf_chunking_strategy);
            let xlsx = convertToDropdownFields(action.payload.xlsx_chunking_strategy);
            let csv = convertToDropdownFields(action.payload.csv_chunking_strategy);
            let docx = convertToDropdownFields(action.payload.docx_chunking_strategy);
            let models = convertToDropdownFields(action.payload.embedding_models);
            return {
                pptxStrategies: pptx,
                pdfStrategies: pdf,
                xlsxStrategies: xlsx,
                csvStrategies: csv,
                docxStrategies: docx,
                embeddingModels: models,
                selectedpptxStrategy: pptx[0],
                selectedpdfStrategy: pdf[0],
                selectedxlsxStrategy: xlsx[0],
                selectedcsvStrategy: csv[0],
                selecteddocxStrategy: docx[0],
                selectedembeddingModel: models[0],
                anonymizeChunks: action.payload.anonymize_chunks === "true",
                chunkSize: parseInt(action.payload.chunk_size),
                chunkOverlap: parseInt(action.payload.chunk_overlap),
                minChunkSize: parseInt(action.payload.chunk_size_range[0]),
                maxChunkSize: parseInt(action.payload.chunk_size_range[1]),
                minChunkOverlap: parseInt(action.payload.chunk_overlap_range[0]),
                maxChunkOverlap: parseInt(action.payload.chunk_overlap_range[1]),
                treatTableSeparately: action.payload.treat_tables_separately === "true"
            }
        case actionTypes.SET_PPTX_CHUNKING_STRATEGY:
            return {
                ...state,
                selectedpptxStrategy: action.payload
            }
        case actionTypes.SET_PDF_CHUNKING_STRATEGY:
            return {
                ...state,
                selectedpdfStrategy: action.payload
            }
        case actionTypes.SET_DOCX_CHUNKING_STRATEGY:
            return {
                ...state,
                selecteddocxStrategy: action.payload,
            }
        case actionTypes.SET_CSV_CHUNKING_STRATEGY:
            return {
                ...state,
                selectedcsvStrategy: action.payload
            }
        case actionTypes.SET_XLSX_CHUNKING_STRATEGY:
            return {
                ...state,
                selectedxlsxStrategy: action.payload
            }
        case actionTypes.SET_EMBEDDING_MODEL:
            return {
                ...state,
                selectedembeddingModel: action.payload,
            }
        case actionTypes.SET_CHUNK_SIZE:
            return {
                ...state,
                chunkSize: action.payload,
            }
        case actionTypes.SET_CHUNK_OVERLAP:
            return {
                ...state,
                chunkOverlap: action.payload,
            }
        case actionTypes.SET_ANONYMIZE_CHUNK:
            return {
                ...state,
                anonymizeChunks: action.payload,
            }
        case actionTypes.SET_TREAT_TABLES_SEPARATELY:
            return {
                ...state,
                treatTableSeparately: action.payload
            }
        default:
            return state;
    }
}

const convertToDropdownFields = (strategies) => {
    let newStrategies = []
    strategies.map((strategy)=>{
        newStrategies.push({"value":strategy, "label": strategy});
    });
    return newStrategies;
}

export default chunkingSettingsReducer;
