import React, { useState } from "react";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
	const [callGuide, setCallGuide] = useState(0);
	const [userName, setUserName] = useState(0);
	const [profileImage, setProfileImage] = useState(0);
	const [loading, setLoading] = useState(0);
	const [user, setUser] = useState(0);
	const [userId, setUserId] = useState(0);
	const [sections, setSections] = useState(0);
	const [callGuideCteated, setCallGuideCteated] = useState(false);
	const [sectionsSelected, setSectionsSelected] = useState(false);
	const [userEmail, setUserEmail] = useState(0)
	const [accessToken, setAccessToken] = useState(0)
	const [activeAccount, setActiveAccount] = useState(0)

	return (
		<Context.Provider value={{ callGuide, setCallGuide, userName, setUserName, profileImage, setProfileImage, 
			loading, setLoading, user, setUser, userId, setUserId, sections, setSections, callGuideCteated, setCallGuideCteated, 
			sectionsSelected, setSectionsSelected, userEmail, setUserEmail, accessToken, setAccessToken, activeAccount, setActiveAccount }}>
			{children}
		</Context.Provider>
	);
};
