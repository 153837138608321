import * as actionTypes from "../action-types.js";

const initialState = {
  temperature: 0,
  maxP: 0,
  maxTokens: 0,
  frequencyPenalty: 0,
  presencePenalty: 0,
  chunkSearchSize: 0,
  minChunkSearchSize: 0,
  maxChunkSearchSize: 0
};

const openAIParameterReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_ALL_OPENAI_PARAMETERS:
            return {
                temperature: parseFloat(action.payload.open_ai_temp),
                maxP: parseFloat(action.payload.open_ai_max_p),
                maxTokens: parseInt(action.payload.open_ai_token),
                frequencyPenalty: parseFloat(action.payload.open_ai_frequency_penalty),
                presencePenalty: parseFloat(action.payload.open_ai_presence_penalty),
                minChunkSearchSize: parseInt(action.payload.chunks_n_range[0]),
                maxChunkSearchSize: parseInt(action.payload.chunks_n_range[1]),
                chunkSearchSize: parseInt(action.payload.chunks_n)
            }
        case actionTypes.SET_TEMPERATURE:
            return {
            ...state,
            temperature: action.payload
            }
        case actionTypes.SET_MAX_P:
            return {
            ...state,
            maxP: action.payload
            }
        case actionTypes.SET_MAX_TOKENS:
            return {
            ...state,
            maxTokens: action.payload,
            }
        case actionTypes.SET_FREQUENCY_PENALTY:
            return {
            ...state,
            frequencyPenalty: action.payload
            }
        case actionTypes.SET_PRESENCE_PENALTY:
            return {
            ...state,
            presencePenalty: action.payload
            }
        case actionTypes.SET_CHUNK_SEARCH_SIZE:
            return {
            ...state,
            chunkSearchSize: action.payload,
            }
        default:
            return state;
    }
}

export default openAIParameterReducer;
