import { useEffect, useContext, useState } from 'react';
import Chatbot from "react-chatbot-kit";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import 'react-chatbot-kit/build/main.css';
import Config from "./chatbot/Config";
import MessageParser from "./chatbot/MessageParser";
import ActionProvider from "./chatbot/ActionProvider";
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/authConfig';
import "./App.css";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { Context } from "./Context";
import ChatColumnComponent from './components/chat-history-column/chat-history-column';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NavigationButton from './components/navigation-buttons/navigation-button';
import EmbeddingPage from './components/ui-views/embedding-page';
import EmbeddingsApi from './api-client/api-embeddings';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../../react-TSG-ctka1/src/state/action-types';
import { loadNotification, removeNotification } from './state/action-creators/notification-action';
import UserGuideButton from './components/user-guide/user-guide-tooltip';
import LoadingComponent from './components/loading/loading';
import * as XLSX from "xlsx";
import NormalButton from './components/navigation-buttons/normal-button';
import ChatLoading from './components/chat-loading/chat-loading';
import { appInsights } from '../src/appinsights/appInsights';

const botName = process.env.REACT_APP_BOTNAME;
const appName = botName.charAt(0).toUpperCase() + botName.slice(1)

// const dotenv = require("dotenv");
// dotenv.config({ path: ".env" }); 

const msalInstance = new PublicClientApplication(msalConfig);

const MainContent = () => {
  const accounts = msalInstance.getAllAccounts();
  const { setProfileImage } = useContext(Context);
  const { setUserName } = useContext(Context);
  const { setUser } = useContext(Context);
  const { userId, setUserId } = useContext(Context);
  const {userEmail, setUserEmail} = useContext(Context);
  const {accessToken, setAccessToken} = useContext(Context);
  const {activeAccount, setActiveAccount} = useContext(Context);
  const [hasCTKAUserAccess, setHasCTKAUserAccess] = useState(false);
  const [hasCTKAAdminAccess, setHasCTKAAdminAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const globalMessage = useSelector((state) => state.global.global_message);
  const notifs = useSelector((state) => state.notification.notifications);
  const embeddingsApi = EmbeddingsApi();

  useEffect(() => {
    dispatch(loadNotification());
  }, []);

  useEffect(() => {
    if(notifs !== null && notifs.length > 0){
      const curNotif = notifs[0];
      async function getNotifs(){
        return await embeddingsApi.getNotification({teamId: curNotif.teamId, sessionId: curNotif.sessionId})
      }
      let interval = null;
      if(Date.now()-curNotif.notifTime < 30*60*1000){
        interval = setInterval(() => {
          getNotifs().then((response)=>{
            if(Date.now()-curNotif.notifTime > 30*60*1000){
              dispatch(removeNotification(curNotif.sessionId));
              clearInterval(interval);
            }
            if(response.data.is_processed === true){
              toast.success(`Your session '${curNotif.sessionName}' has been created.`,{
                position: toast.POSITION.BOTTOM_RIGHT
              });
              dispatch(removeNotification(curNotif.sessionId));
              clearInterval(interval);
            }
            // else if(response.data.is_processed === true && response.data.is_error === true){
            //   toast.warning(`Session '${curNotif.sessionName}' has been created.\n 
            //                 But some files are not processed.`,{
            //     position: toast.POSITION.BOTTOM_RIGHT
            //   });
            //   dispatch(removeNotification(curNotif.sessionId));
            //   clearInterval(interval);
            // }
          });
        }, 10000);
        return () => clearInterval(interval);
      }else{
        dispatch(removeNotification(curNotif.sessionId));
        if(interval !== null) clearInterval(interval);
      }
    }
  }, [notifs]);

  useEffect(() => {
    // Use the same publicClientApplication instance provided to MsalProvider
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
    // console.log(accounts);
    // console.log(msalInstance.getActiveAccount());

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        setActiveAccount(account)
        console.log("event callback"+account);
        msalInstance.setActiveAccount(account);
        setUserName((userName) => account.userName);
        setUser((user) => account.name);
        setUserEmail((userEmail) => account.userEmail);
        setUserId((userId) => account.localAccountId + "." + Math.floor(Math.random() * (999999999999 - 100000000000 + 1)));
        fetchGraphAccessToken();
      }
    }, error => {
      //console.log('error', error);
    });

    // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
      // Check if user signed in 
      const account = msalInstance.getActiveAccount();
      if (!account || isIDTokenExpired(account)) {
        // redirect anonymous user to login page 
        msalInstance.loginRedirect();
      }
      else {
        setActiveAccount(account)
        setUserName(account.username);
        setUser(account.name);
        setUserId((userId) => account.localAccountId + "." + Math.floor(Math.random() * (999999999999 - 100000000000 + 1)));
        fetchGraphAccessToken();
      }
    }).catch(err => {
      // TODO: Handle errors
      //console.log(err);
    });

  }, [])

  useEffect(()=>{
    if(globalMessage.type == actionTypes.GLOBAL_ERROR){
      toast.error(globalMessage.message,{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }else if(globalMessage.type == actionTypes.GLOBAL_LOADING){
      toast.loading(globalMessage.message,{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }else if(globalMessage.type == actionTypes.GLOBAL_WARNING){
      toast.warning(globalMessage.message,{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }else if(globalMessage.type == actionTypes.GLOBAL_SUCCESS){
      toast.success(globalMessage.message,{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }else{
      toast.dismiss();
    }
  }, [globalMessage])

  const isIDTokenExpired = (activeAccount) => {
    const idTokenExpires = new Date(activeAccount.idTokenClaims.exp * 1000);
    const currentTime = new Date();
    // Calculate the difference in milliseconds between the expiry date and the current time
    const timeDifference = idTokenExpires.getTime() - currentTime.getTime();
  
    if (timeDifference <= 0) {
      return true;
    } else if (timeDifference <= 5 * 60 * 1000) { // 5 minutes = 5 * 60 * 1000 milliseconds
      return true;
    } else {
      return false;
    }
  }

  const fetchGraphAccessToken = () => {
    msalInstance.acquireTokenSilent({
      scopes: ["user.read"],
      account: accounts[0],
    })
      .then(function (accessTokenResponse) {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        setAccessToken(accessToken);
        try {
          embeddingsApi.getUserAccess().then((response) => {
            setHasCTKAUserAccess(response.isUser);
            setHasCTKAAdminAccess(response.isAdmin);
            setLoading(false)
          });
          fetchEmployeeProfilePicture(accessToken)
        } catch (err) {
          console.error(err, "err");
        }
      })
      .catch(function (error) {
        //Acquire token silent failure
        console.log(error);
      });
  }
 
  // set max tries as 3.
  const fetchEmployeeProfilePicture = (accessToken) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);
    headers.append("Content-type", 'blob');
    const requestOptions = {
      method: "GET",
      headers: headers,
    };
    const url = `https://graph.microsoft.com/v1.0/me/photo/$value`;

    fetch(url, requestOptions)
      .then((response) => {
        if (response != null && response.ok) {
          response.blob().then((data) => {
            if (data !== null) {
              window.URL = window.URL || window.webkitURL;
              setProfileImage((profileImage) => window.URL.createObjectURL(data));
            }
          });
        } else {
          // console.log("I am here hkfhjds ,mbsakjfdgkjsagfkgafs mafskjlas");
          // const retried = retryCount;
          // if(retryCount < MAX_RETRY_LIMIT){
          //   setRetryCount(retried+1);
          //   console.log(retryCount);
          //   fetchEmployeeProfilePicture(accessToken);
          // }
        }
      })
      .catch((error) => {
        // const retried = retryCount;
        // if(retryCount < MAX_RETRY_LIMIT){
        //   setRetryCount(retried+1);
        //   // console.log(retryCount);
        //   fetchEmployeeProfilePicture(accessToken);
        // }
      });
  }

  var chatbot;
  const validator = (input) => {
    if (input.length > 0) return true;
    return false
  };

  chatbot = <Chatbot
    config={Config}
    messageParser={MessageParser}
    actionProvider={ActionProvider}
    validator={validator}
  />
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */

  const getEmbedKPIs = async() =>{
    try{
        const response = await embeddingsApi.getCTKAEmbedKPIs();
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(response.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'embed_data.xlsx', { bookType: 'xlsx' });
    }catch (error){
        console.log(error.message);
    }
}

const getChatKPIs = async() =>{
    try{
        const response = await embeddingsApi.getCTKAChatKPIs();
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(response.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'chat_data.xlsx', { bookType: 'xlsx' });
    }catch (error){
        console.log(error.message);
    }
}
  return (
    <>
      {activeAccount ? (
        <div className="containerdiv">
           {hasCTKAUserAccess && !loading? 
            <div>
              <div className="row rowheader">
                <div className="col topBanner">
                  <nav className="navbar navbar-light bg-light">
                    <div className="row navRow">
                      <div className='col-md-6 navAppName'>
                        <h4 className="title " href="#">
                          Case Team Knowledge Assistant
                        </h4>
                      </div>
                      {hasCTKAAdminAccess?
                        <div style={{display: 'flex', flexDirection:'row',marginTop: "3px", 
                                width: '420px', alignSelf:'center', marginRight: "240px"}}>
                          <NormalButton
                              text={"Chat KPIs"} 
                              handleActionClick={getChatKPIs}/>
                          <div style={{width: "10px"}}/>    
                          <NormalButton
                              text={"Embed KPIs"} 
                              handleActionClick={getEmbedKPIs}/>
                        </div>:""
                      }
                    </div>
                  </nav>
                </div>
              </div>
            <Router>
              <div>
                <Routes>
                  <Route path='/'
                    element={
                      <div className='main-container'>
                        <div className='navigation-container'>
                          <NavigationButton path={'chat'} text={" Chat "} type={"navigate"}></NavigationButton>
                          <UserGuideButton/>
                        </div>
                        <EmbeddingPage/>
                      </div>
                    }>
                    </Route>
                    <Route path='/chat'
                    element={
                      <div className='main-container'>
                        <div className='navigation-container'>
                          <NavigationButton path={''} text={"File System"} type={"navigate"}></NavigationButton>
                          <UserGuideButton/>
                        </div>
                        <ChatColumnComponent/>
                        <div className='right-view-container'>
                          {chatbot}
                        </div>
                        <div style={{position:"absolute","right":"0","marginRight":"70px","marginTop":"15px", "zIndex":"5"}}>
                          <ChatLoading/>
                        </div>
                      </div>
                    }>
                    </Route>
                </Routes>
              </div>
            </Router>  
            </div>
           :
            <div className='access-main-container'>
                {loading ?  
                  <div className="access-message">
                    Checking access requirements for CTKA<LoadingComponent height={28} width={28} trackcolor={"#CC0000"} sectioncolor={"rgb(255, 244, 243)"} loading={loading} />
                  </div>
                  :
                  <div>
                    <div className="access-message">
                      You are not a member of the group with access to CTKA app
                    </div>
                    <div className="access-message">
                      Please contact 'Bain.TSGHelpdesk@Bain.com'
                    </div>
                  </div>
                }
            </div>
            }        
        </div>
      ) : null}
    </>
  );
};

function App(instance) {
  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
      <ToastContainer/>
    </MsalProvider>
  );
}

export default App;
