import * as actionType from "../action-types.js";

const setChatContent = (data, chatId) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_CONTENT,
            payload: {data, chatId}
        });
    };
};

const setChatResponse = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_RESPONSE,
            payload: data
        });
    }
}

const setChatId = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_ID,
            payload: data,
        });
    };
};

const setChatName = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_NAME,
            payload: data,
        });
    };
};

const refreshChatDetails = () => {
    return (dispatch) => {
        dispatch({
            type: actionType.REFRESH_CHAT_DETAILS
        });
    }
}

export { setChatContent, setChatResponse, setChatId, setChatName, refreshChatDetails };

