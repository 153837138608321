import * as actionType from "../action-types.js";

const setTeamRecordsSuccess = (data, callForFile) => {
  return (dispatch) => {
    dispatch({
      type: actionType.SET_TEAM_GROUP_RECORDS_SUCCESS,
      payload: {data, callForFile},
    });
  };
};

const setTeamRecordsFail = (error) => {
    return (dispatch) => {
      dispatch({
        type: actionType.SET_TEAM_GROUP_RECORDS_FAIL,
        error: error,
      });
    };
  };

const setTeamRecordsLoadingError = (loading, error) => {
  return (dispatch) => {
    dispatch({
      type: actionType.SET_TEAM_GROUP_LOADING_AND_ERROR,
      payload: {loading, error},
    });
  }
}


export { setTeamRecordsSuccess, setTeamRecordsFail, setTeamRecordsLoadingError };
