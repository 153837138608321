import BotAvatar from "../../components/BotAvatar";
import MessageRating from "../MessageRating";
// import { useState } from 'react';
import './custom-message.css';

const CustomMessage = (props) => {

    // const [hovered, setHovered] = useState(false);

    // const onMouseEnter = () =>{
    //     setHovered(true);
    // }

    // const onMouseLeave = () =>{
    //     setHovered(false);
    // }

    return (
        <>
            <div className="custom-message-main-container"
                // onMouseEnter={onMouseEnter}
                // onMouseLeave={onMouseLeave}
            >
                <BotAvatar/>
                <div className="message-triangle"/>
                <div className="message-content">
                    {props.payload.message}
                    <div className="rating-container">
                        <MessageRating props={props}/>
                    </div>
                </div>
                {/* {hovered 
                    ?<div className="rating-container">
                        <MessageRating props={props}/>
                    </div>
                    :null
                } */}
            </div>
        </>
    );
}

export default CustomMessage;