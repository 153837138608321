import * as actionTypes from "../action-types.js";

const initialState = {
  teamGroups: [],
  loading: true,
  error: null,
  callForFile: false
};

const teamGroupReducer = (state= initialState, action) => {
    switch(action.type){
      case actionTypes.SET_TEAM_GROUP_RECORDS_SUCCESS:
        return {
          ...state,
          teamGroups: action.payload.data,
          loading: false,
          callForFile: action.payload.callForFile,
        }
      case actionTypes.SET_TEAM_GROUP_RECORDS_FAIL:
        return {
          ...state,
          error: action.error,
          loading: false,
        }
      case actionTypes.SET_TEAM_GROUP_LOADING_AND_ERROR:
        return {
            ...state,
            error: action.payload.error,
            loading: action.payload.loading,
            callForFile: false,
        }
    default:
        return state;
    }
}

export default teamGroupReducer;
