import React, { useState, useEffect } from 'react';
import EmbeddingsApi from "../api-client/api-embeddings";
import { useDispatch, useSelector } from 'react-redux';
import InitialMessage from '../components/InitialMessage';
import { createClientMessage, createCustomMessage} from 'react-chatbot-kit';
import {v4 as uuid} from 'uuid';
import { setChatResponseLoading } from '../state/action-creators/chat-loading-action';
import { setGlobalError } from '../state/action-creators/global-action';

const ActionProvider = ({ createChatBotMessage, setState, children}) => {

  const embeddingsApi = EmbeddingsApi();
  const chatHistory = useSelector((state) => state.chat_details.chatResponse);
  const dispatch = useDispatch();
  var initialMessagesTemplate = <InitialMessage botName={"CTKA"}></InitialMessage>;
  const initialMessages = createChatBotMessage(
    initialMessagesTemplate,
    {
      withAvatar: true,
      content: "Target",
    }
  );

  useEffect(()=>{
    let chatHistoryResponse = [initialMessages];
    chatHistory.map((item)=>{
      if(item.role === "user"){
        const newMessage = createClientMessage(
          item.content
        );
        chatHistoryResponse.push(newMessage);
      }else{
        const newMessage = createCustomMessage(
          item.content, 'custom',
          {
            payload: {
              "message":item.content, 
              "message_id": item.message_id ?? "fwdfdsfds", 
              "rating": item.rating ?? "0", 
              "feedback":item.feedback === undefined ? "" :item.feedback,
              "feedback_timestamp": item.feedback_timestamp ?? ""
            }
          }
        );
        chatHistoryResponse.push(newMessage);
      }
    });
    setState((prev) => ({
      messages: chatHistoryResponse
    }));
  },[chatHistory]);

  const generateMessageId = () =>{
    const x = uuid();
    return x;
  }

  const handleUserMessage = async (userMessage) => {
    try {
      dispatch(setChatResponseLoading(true));
      let messageId = generateMessageId();
      let response = await embeddingsApi.getChatResponse({ userMessage: userMessage, message_id: messageId });
      if (response.data?.length > 0){
        const chatdata = response.data;
        const botMessage = chatdata[0].content;
        const newMessage = createCustomMessage(botMessage, 'custom',{
          payload: {
            "message":botMessage, 
            "message_id": messageId, 
            "rating":"0", 
            "feedback":"",
            "feedback_timestamp": ""
          }
        });
        dispatch(setChatResponseLoading(false));
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, newMessage]
        }));
      }
      else if(response.response.status === 500){
        // console.log("Error:", response.response.data);
        dispatch(setGlobalError(response.response.data.message));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {handleUserMessage,}
        });
      })}
    </div>
  );
}

export default ActionProvider;
