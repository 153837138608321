import * as actionTypes from "../action-types.js";

const initialState = {
    fileSessionDetail: []
};

const fileSessionDetailReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_FILE_SESSION_DETAIL:
            return {
                ...state,
                fileSessionDetail: action.payload
            }
      default:
        return state;
    }
}

export default fileSessionDetailReducer;
