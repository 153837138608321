import React, { useState } from 'react';
import { useEffect } from "react";
import '../file-explorer/session-tooltip/session-tooltip.css';
import './user-guide-tooltip.css';
import Modal from 'react-modal';
Modal.setAppElement('#root');

const UserGuideButton = ({ id }) => {
    const customModalStyle = {
        content: {
            width: '900px',
            height: '600px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
    };
    useEffect(()=>{
        var introShown = JSON.parse(localStorage.getItem("intro-shown"));
        if(!introShown){
            openModal();            
            localStorage.setItem('intro-shown', JSON.stringify(true));
        }
    },[]);
    // Style for the scrollable content area
    const scrollableContentStyle = {
        overflow: 'auto',
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', width: "fit-content", marginBottom: "2px" }}>
            <svg onClick={openModal}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                stroke="rgb(204,0,0)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="16" x2="12" y2="12" />
                <line x1="12" y1="8" x2="12" y2="8" />
            </svg>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customModalStyle}
            >
                <>
                    <div className='modal-header'>
                        <strong>CTKA User Guide</strong>
                        <svg onClick={closeModal}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            color='#fff'
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ cursor: "pointer" }} // Add cursor pointer style
                        >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>

                    </div>
                    <div style={scrollableContentStyle}>
                        <table className="data-table session-table file-table-header">
                                <tr>
                                    <td colspan="2">
                                        <strong>
                                            General Observations
                                        </strong>
                                    </td>                                   
                                </tr>
                                <tr className='noborder-row'>
                                    <td colspan="2">
                                        Please be aware that CTKA is currently in its Beta testing phase, therefore you may encounter instabilities or errors when using the system.

                                        Furthermore, it is important to note that uploading files larger than 20MB in size to CTKA can result in system instabilities.
                                    </td>                                   
                                </tr>
                                <br></br>
                                <tr>
                                    <td colspan="2">
                                        <strong>
                                            How to use the CTKA Guide
                                        </strong>
                                    </td>                                   
                                </tr>
                                <tr>
                                    <td className='col-sm-6 col-md-6 col-lg-6'>
                                        <u>
                                            To upload your files:
                                        </u>
                                    </td>
                                    <td className='col-sm-6 col-md-6 col-lg-6'>
                                        <u>
                                            To chat with CTKA:
                                        </u>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='col-sm-6 col-md-6 col-lg-6' style={{verticalAlign: "top"}}>
                                        <ol className='guidelines'>
                                            <li>
                                                Click in the File System button (right upper corner); if your button says "Chat", it means you are already in the File System
                                            </li>
                                            <li>
                                                Select your case team cloud and select the desired files to upload
                                            </li>
                                            <li>
                                                Name your session
                                            </li>
                                            <li>
                                                Click in the "Create Session" button
                                            </li>
                                        </ol>
                                    </td>
                                    <td className='col-sm-6 col-md-6 col-lg-6' style={{verticalAlign: "top"}}>
                                        <ol className='guidelines'>
                                            <li>
                                                Click in the Chat button (right upper corner); if your button says "File System", it means you are already in the chat window
                                            </li>
                                            <li>
                                                Select your case team cloud and select the session you want to use
                                            </li>
                                            <li>
                                                Chat interface is ready to go
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='col-sm-6 col-md-6 col-lg-6'>
                                        <a href="https://int3.video.bain.com/evergreen/detail/videos/ctka-tutorial" target="_blank">Watch Tutorial Video</a>
                                    </td>
                                </tr>
                                <tr className='noborder-row'>
                                    <td className='col-sm-6 col-md-6 col-lg-6'>
                                        <a href="https://bainandcompany.sharepoint.com/:b:/s/Global_CTKA_Beta_Team_Teams/EY_H80gRvtdBmUc52f4gI0IBic2NmL5SHDF4_yemgrmyAw?e=QoZZMY" target="_blank">Read CTKA Guide</a>
                                    </td>
                                </tr>
                        </table>
                    </div>
                </>
            </Modal>
        </div>
    );
};

export default UserGuideButton;
