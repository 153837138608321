import React from "react";
import TeamComponent from "../team-detail/team-component";
import './team-list.css';

const CaseTeamClouds =({caseClouds}) =>{

    return(
        <>
            <ul>
                {
                    caseClouds.map((item, index)=>(
                        <TeamComponent key={index} text={item.displayName} itemId={item.id}></TeamComponent>
                    ))
                }
            </ul>
        </>
    );
}

export default CaseTeamClouds;