import { useDispatch, useSelector } from "react-redux"
import { MdOutlineCheckCircleOutline, MdClose } from 'react-icons/md';
import LoadingComponent from "../loading/loading";
import { useEffect, useState } from "react";
import './chat-loading.css';
import { setChatResponseSave } from "../../state/action-creators/chat-loading-action";

const ChatLoading = () =>{
    const responseLoading = useSelector((state) => state.chatLoading.loading);
    const responseSaved = useSelector((state) => state.chatLoading.saved);
    const responseError = useSelector((state) => state.chatLoading.error);
    const [showSave, setShowSave] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let timer = null;
        if(responseSaved){
            setShowSave(responseSaved);
            timer = setTimeout(() => {
                setShowSave(false);
                dispatch(setChatResponseSave(false));
              }, 3000);
        }
        return () => timer !== null ?clearTimeout(timer) : null;
    }, [responseSaved]);

    return(
        <>
            {responseLoading 
                ?<div className="loading-row-container">
                    <span style={{color: "white"}}>Loading Response</span>
                    <LoadingComponent height={"20px"} width={"20px"} loading={responseLoading}/>
                 </div>
                : showSave
                    ? <div className={showSave ? "fadeIn" :"fadeOut"}>
                        <span style={{color: "white", marginRight: "5px"}}>Chat Saved</span>
                        <MdOutlineCheckCircleOutline style={{color: "white"}}/>
                     </div>
                    : responseError !== ""
                        ? <div className="loading-row-container">
                            <span style={{color: "white", marginRight: "5px"}}>Error while loading response.</span>
                            <MdClose style={{color: "white"}}/>
                         </div>
                        : null
            }
        </>
    );
}

export default ChatLoading;