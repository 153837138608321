import React, { useState } from 'react';
import FileDetailsComponent from '../file-details-list/file-component';
import './file-explorer.css';
import TopList from '../top-list/top-list.jsx';
import NavigationButton from '../../navigation-buttons/navigation-button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ErrorComponent from '../../error-handling/error-component.jsx';
import EmbeddingsApi from '../../../api-client/api-embeddings';
import { removeSelectedFiles, setSelectAllClicked, setSession } from '../../../state/action-creators/selectedfiles-action';
import { ToastContainer, toast } from 'react-toastify';
import LoadingComponent from '../../loading/loading';
import { setNotification } from '../../../state/action-creators/notification-action';
import { v4 as uuid } from 'uuid';

const FileExplorer = () => {
  const topListInitialState = [{'name':"Back", "type":"button", "items":[], "metadata":{}, "id":"root"}];
  const [currentDir, setCurrentDir] = useState([]);
  const [topList, setTopList] = useState(topListInitialState);
  const dispatch = useDispatch();
  const teamGroups = useSelector((state) => state.team_groups.teamGroups);
  const loading = useSelector((state)=> state.team_groups.loading);
  const error = useSelector((state)=>state.team_groups.error);
  const callForFile = useSelector((state)=>state.team_groups.callForFile);
  const selectedCaseCloud = useSelector((state)=>state.selected_files.selectedCaseCloud);
  const [currentFolder, setCurrentFolder] = useState();
  const selectedFiles = useSelector((state) => state.selected_files.selectedFiles);
  const currentSessionname = useSelector((state) => state.selected_files.sessionName);
  const embeddingsApi = EmbeddingsApi();
  const [folderClicked, setFolderClicked] = useState(false);

  useEffect(()=>{
    if(callForFile){
      const newFileObject = {"name": currentFolder, "value": teamGroups};
      if(teamGroups.length > 0){
        if(currentDir.name !== currentFolder){
          setCurrentDir(newFileObject);
          setTopList(prevList => [...prevList, newFileObject])
        }
      }else{
        setCurrentDir(newFileObject);
        setTopList(prevList => [...prevList, newFileObject])
      }
      setFolderClicked(false);
    }else{
      const newFileObject = {"name": selectedCaseCloud.name, "value": teamGroups};
      setCurrentDir(newFileObject);
      setTopList(topListInitialState.concat(newFileObject));
    }
  },[teamGroups])

  const handleItemClick = async (item) => {
    if (item.hasOwnProperty("folder")) {
      setFolderClicked(true);
      setCurrentFolder(item.name);
      dispatch(removeSelectedFiles(selectedCaseCloud));
      await embeddingsApi.getTeamFiles({driveId: item.parentReference.driveId, itemId: item.id});
    } else {
      toast.warning("Can't open a file!",{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };


  const handleTopListClick = (item, index)=>{
    if(index === topList.length-1){
        // console.log("nothing changes");
    }else{
        if(item.type === 'button' && topList.length > 2){
            dispatch(setSelectAllClicked(false));
            setCurrentDir(topList[topList.length-2]);
            let newList = topList.slice();
            newList.splice(topList.length-1);
            setTopList(newList);
        }else if(item.type !== 'button' && item.folder !== null){
            dispatch(setSelectAllClicked(false));
            setCurrentDir(item);
            let newList = topList.slice();
            newList.splice(index+1);
            setTopList(newList);
        }
    }
  }

  const createEmbeddings = async () =>{
    if(currentSessionname === undefined 
        || currentSessionname.name === undefined 
        || currentSessionname.name === ""){
      toast.error('Please enter a session name!',{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
    else if(selectedFiles.length === 0){
      toast.error('No files selected!',{
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }else{
      // const response = await embeddingsApi.checkSessionName({sessionName: currentSessionname.name})
      // if(response.data.status !== null 
      //     || !response.data.status){
        embeddingsApi.generateEmbeddings()
          .then((response)=>{
            if(response !== undefined && response.data.message === 'Messages sent successfully!'){
              toast.success('Your session is being processed.', {
                position: toast.POSITION.BOTTOM_RIGHT
              });
              // dispatch(setNotificationResponse(selectedFiles));
              dispatch(setNotification({teamId: selectedCaseCloud.id, 
                                        sessionId: currentSessionname.id,
                                        sessionName: currentSessionname.name,
                                        error: ""}));
              dispatch(setSession({}));
            }else{
              toast.error('Unable to create session!',{
                position: toast.POSITION.BOTTOM_RIGHT
              });
            }
          });
      // }else{
      //   toast.error('Session Name already exist',{
      //     position: toast.POSITION.BOTTOM_RIGHT
      //   });
      // }
    }
  }

  const handleOnSessionNameChange = (event) =>{
    const name = event.target.value;
    const id = uuid();
    dispatch(setSession({name:name, id:id}));
  }

  return (
    <div className='parnet-div'>
      <div className='react-chatbot-kit-chat-header'>
        <div className='col-6'>File Explorer</div>
        <div className='top-col'>
          <LoadingComponent loading={folderClicked} height={"18"} width={"18"}></LoadingComponent>
          {/* <span className='embeddings-cost'>Embeddings Cost:<div className='cost-field'>{" US $ ##"}</div> </span> */}
        </div>
      </div>
      {
        <div className='row-list'>
          {topList.map((item, index) => (
            <TopList key={index} dirName={item.name} onDirClick={()=>handleTopListClick(item, index)}/>
          ))}
        </div>
      }
      <div className='file-list'>
        {loading ? <div>Please select a teams case cloud...</div>
                : error != null
                      ? <ErrorComponent error={error.message}></ErrorComponent>
                      : <FileDetailsComponent items={currentDir.value} handleParentFileClick={handleItemClick}/>
                    }
      </div>
      <div className = 'embeddings-button-list'>
        <input
            className="react-chatbot-kit-chat-input session-name-border"
            style={{height: "40px", width: "300px", marginRight: "10px"}}
            type="text"
            placeholder="Enter new session name"
            onChange={handleOnSessionNameChange}
            value={currentSessionname.name !== undefined ? currentSessionname.name :""}
        />
        <NavigationButton text={"Create Session"} handleActionClick={()=>createEmbeddings()}></NavigationButton>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default FileExplorer;
