import React, { useEffect, useState } from 'react';
import Select, {components} from 'react-select';
import './dropdown-component.css';
import { useDispatch, useSelector } from 'react-redux';
import { setEmbeddings, setSelectedEmbedding, setSessionDropDownOptions } from '../../state/action-creators/embedding-actions';
import EmbeddingsApi from '../../api-client/api-embeddings';
import { setSelectedTeam, setTeamDropDrownOptions } from '../../state/action-creators/teamList-action';
import { refreshChatDetails, setChatContent } from '../../state/action-creators/chat-action';
import { setChatListSuccess } from '../../state/action-creators/chat-list-actions';
import { setSessionFiles } from '../../state/action-creators/session-files-action';
import { setNavigated } from '../../state/action-creators/navigation-action';

const DropdownWithSearch = () => {

  const dispatch = useDispatch();
  const embeddingsApi = EmbeddingsApi();
  const embeddingsList = useSelector((state) => state.embedding_details.embeddings);
  const selectedEmbeddingSet = useSelector((state) => state.embedding_details.selectedEmbedding);
  const selectedTeam = useSelector((state) => state.team_list.selectedTeam);
  const teamList = useSelector((state) => state.team_list.teamList);
  const preserveState = useSelector((state) => state.navigation.navigated);
  const sessionDropdownOptions = useSelector((state) => state.embedding_details.sessionDropDownOptions);
  const teamsDropdownOptions = useSelector((state) => state.team_list.teamDropDownOptions);
  const selectedChat = useSelector((state) => state.chat_details.chatId);

  useEffect(() => {
    if(preserveState === false){
      let newTeamList = [];
      teamList.map((team) => {
        const newTeamItem = {"value": team.id, "label": team.displayName};
        newTeamList.push(newTeamItem);
      });
      dispatch(setTeamDropDrownOptions(newTeamList));
    }
  }, [teamList]);


  useEffect(async ()=>{
    if(preserveState === false){
      dispatch(setSelectedEmbedding({ value: 'loadingsessions', label: 'Loading your sessions..' }));
      // console.log("changed team");
      dispatch(setChatContent([], ""));
      dispatch(refreshChatDetails());
      await embeddingsApi.getEmbeddings(selectedTeam, false);
    }
  },[selectedTeam]);

  useEffect(()=>{
    if(preserveState === false){
      let newEmbeddingSet = [];
      if(embeddingsList !== null && embeddingsList.length != 0)
      {
        embeddingsList.map((set)=>{
          const newItem = {"value": set.session_id, "label": set.session_name, "index": set.index_name};
          newEmbeddingSet.push(newItem);
        });
        // const anotherItem = {"value": "checking for long text if we are enabling the scroll or it should be cropped", "label": "checking for long text if we are enabling the scroll or it should be cropped_whatifthereisnospacehowwillithandletheissue", "index": "set.index_nam"};
        // newEmbeddingSet.push(anotherItem);
        dispatch(setSessionDropDownOptions(newEmbeddingSet));
      }
      else{
        dispatch(setSessionDropDownOptions(null));
      }
    } 
  }, [embeddingsList])

  useEffect(()=>{
    if(preserveState === false){
      if(selectedEmbeddingSet.value !== 'loadingsessions' 
          && selectedEmbeddingSet !== null)
          {
            async function getChatList(){
              await embeddingsApi.getChatList();
            }
            getChatList();
            
            async function getSessionFiles(){
              await embeddingsApi.getSessionFiles();
            }
            getSessionFiles();
            
            enableChatBox();
          }
      else
      {
        dispatch(setChatListSuccess([]));
        dispatch(setSessionFiles([]));
        disableChatBox();
      }
    }else{
      if(selectedEmbeddingSet.value !== 'loadingsessions' 
          && selectedEmbeddingSet !== null)
          {
            enableChatBox();
          }
      else
      {
        disableChatBox();
      }
    }
    if(selectedChat !== undefined && selectedChat !== ""){
      async function getChatHistory(){
        await embeddingsApi.getChatHistory({chatId: selectedChat});
      }
      // console.log(selectedChat);
      getChatHistory();
    }
  }, [selectedEmbeddingSet]);
  
  const disableChatBox = () => {
    const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
    const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
    inputElement.placeholder = "No session present, please create a session to start chatting"
    inputElement.disabled = true;
    buttonElement.disabled = true;
  };

  const enableChatBox = () => {
    const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
    const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
    inputElement.placeholder = "Write your message here"
    inputElement.disabled = false;
    buttonElement.disabled = false;
  };

  const handleSessionChange = async (selected) => {
    dispatch(setNavigated(false));
    dispatch(refreshChatDetails());
    dispatch(setChatListSuccess([]));
    let index = "";
    let files = [];
    let createddate = "";
    let createdby = "";
    embeddingsList.map((item)=>{
      if(item.session_id === selected.value){
        index = item.index_name;
        files = item.file_details;
        createddate = item.created_date;
        createdby = item.created_by;
      }
    });
    const newSelected = {"value": selected.value , "label": selected.label, "index": index, "files": files, "createddate": createddate, "createdby": createdby};
    dispatch(setSelectedEmbedding(newSelected));
  }

  const handleTeamsChange = async (selected) => {
    // console.log(selected)
    dispatch(setNavigated(false));
    dispatch(setSelectedTeam(selected));
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };
  const CustomOption = ({ label, data, ...props }) => {
    return (
      <div title={label}>
        <components.Option {...props}>
          {label}
        </components.Option>
      </div>
    );
  };
  return (
    <>
      <div className='dropdown-select'>
        <Select
            className="single-select"
            classNamePrefix="react-select"
            value={selectedTeam}
            onChange={handleTeamsChange}
            options={teamsDropdownOptions}
            isSearchable={true}
            placeholder="Search your teams..."
        />
      </div>
      <div className='dropdown-select' title={selectedEmbeddingSet.label}>
        <Select
            className="single-select"
            classNamePrefix="react-select"
            value={selectedEmbeddingSet}
            onChange={handleSessionChange}
            options={sessionDropdownOptions}
            isSearchable={true}
            placeholder="Search your embedding..."
            components={{ Option: CustomOption }}
        />
      </div>
    </>
  );
};

export default DropdownWithSearch;