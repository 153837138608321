import {MdOutlineNavigateNext} from 'react-icons/md';
import './top-list.css';
import { Tooltip } from '@carbon/react';

const TopList = ({dirName, onDirClick}) =>{
    return (
        <Tooltip title={dirName ?? ""}>
            <div className='span-list-text' onClick={onDirClick}>
                <span style={{verticalAlign: "middle", fontSize: 16}}>
                    {dirName !== undefined && dirName !== null && dirName.length > 15 ?dirName.substring(0,15)+"..." :dirName}
                </span>
                <MdOutlineNavigateNext style={{marginLeft: "1px"}}/>
            </div>
        </Tooltip>
    )
}

export default TopList;
