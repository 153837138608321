import { createChatBotMessage } from "react-chatbot-kit";
import BotAvatar from "../components/BotAvatar";
import UserAvatar from "../components/UserAvatar";
import InitialMessage from "../components/InitialMessage";
import MessageRating from "./MessageRating";
import CustomMessage from "./custom-message/custom-message";

const botName = process.env.REACT_APP_BOTNAME;

var initialMessagesTemplate = <InitialMessage botName={botName}></InitialMessage>;
const initialMessages = createChatBotMessage(
  initialMessagesTemplate,
  {
    withAvatar: true,
    content: "Target",
  }
)

const config = {
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: "#FFF4F3",
    },
    chatButton: {
      backgroundColor: "#D6D6D6",
    }
  },
  //initialMessages: [createChatBotMessage(`Hi there, I am ${botName}!`)],
  initialMessages: [initialMessages],
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />,
    userAvatar: (props) => <UserAvatar {...props} />,
  },
  customMessages: {
    custom: (props) => <CustomMessage {...props} />,
  },

  state: {
    PreviewState: "",
    Loading: false,
    ParamState: {
      user_id: "",
      revision_prompt: "",
      industry: "",
      target: "",
      sections: "",
      userobjectives: "",
      objectives: "",
      additionalInputs: "",
      temp: parseFloat(process.env.REACT_APP_TEMP),
      max_p: parseFloat(process.env.REACT_APP_MAX_P),
      max_tokens: parseInt(process.env.REACT_APP_MAX_TOKENS),
      frequency_penalty: parseInt(process.env.REACT_APP_FREQUENCY_PENALTY),
      presence_penalty: parseInt(process.env.REACT_APP_PRESENCE_PENALTY),
      chunking_strategy: process.env.REACT_APP_CHUNKING_STRATEGY,
      k: parseInt(process.env.REACT_APP_K),
      nquestions: parseInt(process.env.REACT_APP_NQUESTIONS)
    },
    Sections: ""
  },
  //widgets declaration section which we use in our bot for display information
  widgets: [
    {
      widgetName: "Rating",
      widgetFunc: (props) => <MessageRating {...props} />,
      mapStateToProps: ["PreviewState", "ParamState", "Loading", "User", "UserId"],
    },
  ],
};

export default config;
