import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const AcquireAPIToken = async () => {
    if (msalInstance.getActiveAccount()) {
        var tokenRequest = {
            scopes: ["api://"+ process.env.REACT_APP_CLIENTID +"/access_as_user"],
            forceRefresh: true
        };
        const token = await msalInstance.acquireTokenSilent(tokenRequest);
        return token.accessToken;
    } else {
        console.log("user is not logged in, you will need to log them in to acquire a token");
    }
}

export default AcquireAPIToken;



