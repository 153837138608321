import * as actionTypes from "../action-types.js";

const initialState = {
  chatList: [],
  loading: true,
  error: null
};

const chatListReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_CHAT_LIST_SUCCESS:
            return {
                ...state,
                chatList: action.payload,
                loading: false
            }
        case actionTypes.SET_CHAT_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case actionTypes.SET_CHAT_LIST_LOADING:
            return {
                ...state,
                loading: action.payload,
                chatList: [],
                error: null
            }
      default:
        return state;
    }
}

export default chatListReducer;
