import React, { useContext } from "react";
import { Context } from "../Context";


const InitialMessage = (props) => {
  const botName = process.env.REACT_APP_BOTNAME;
  const greeting = process.env.REACT_APP_INITIAL_GREETING;
  const initial_message = process.env.REACT_APP_INITIAL_MESSAGE;
  const { user } = useContext(Context);

  const full_initial_message = initial_message.replace("[#bot#]", botName);

  let messageHistory = [];
  const newPrompt = [
    {
      "role": "assistant",
      "content": `${greeting} ${user ? user.split(',')[1] : ""}, ${full_initial_message}`
    },
  ]

  messageHistory = [...messageHistory, ...newPrompt];

  localStorage.setItem('messageHistory', JSON.stringify(messageHistory));

  return (
    <div>
      {greeting}<strong>{user ? user.split(',')[1] : ""}</strong>, {full_initial_message}
    </div>
  );
};

export default InitialMessage;
