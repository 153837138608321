import * as actionTypes from "../action-types.js";

const initialState = {
  loading: false,
  saved: false,
  error: ""
};

const chatLoadingReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_RESPONSE_LOADING:
            return {
                loading: action.payload,
                saved: false,
                error: ""
            }
        case actionTypes.SET_CHAT_SAVE:
            return {
                loading: false,
                saved: action.payload,
                error: ""
            }
        case actionTypes.SET_RESPONSE_ERROR:
            return {
                loading: false,
                saved: false,
                error: action.payload
            }
      default:
        return state;
    }
}

export default chatLoadingReducer;
