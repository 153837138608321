import * as actionType from "../action-types.js";

const loadNotification = () => {
    return (dispatch) => {
        dispatch({
            type: actionType.LOAD_NOTIFICATION,
        });
    };
}

const setNotification = ({teamId, sessionId, sessionName, error}) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_NEW_NOTIFICATION,
            payload: {teamId, sessionId, sessionName, error}
        });
    };
};

const removeNotification = (sessionId) => {
    return (dispatch) => {
        dispatch({
            type: actionType.REMOVE_NOTIFICATION,
            payload: sessionId
        });
    };
}

// const setNotificationResponse = (data) => {
//     return (dispatch) => {
//         dispatch({
//             type: actionType.SET_NOTIFICATION_RESPONSE,
//             payload: data
//         });
//     };
// }

export { loadNotification, setNotification, removeNotification
    // , setNotificationResponse 
}