import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './session-tooltip.css';
import Modal from 'react-modal';
Modal.setAppElement('#root');

const SessionTooltipButton = ({id}) => {
    const allTeamFiles = useSelector((state) => state.file_session_details.fileSessionDetail);
    let desiredItem = null;
    if (allTeamFiles?.length > 0) {
        desiredItem = allTeamFiles?.filter(item => item.file_id === id);
    }
    // console.log(desiredItem);
    // Style for the modal content
    const customModalStyle = {
        content: {
            width: '900px',
            height:'500px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
    };

    // Style for the scrollable content area
    const scrollableContentStyle = {
        overflow: 'auto',
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const getExpirationDate = (date) =>{
        const createddate = new Date(date);
        const expiry = new Date().setDate(createddate.getDate() + 30);
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const expirydate = new Date(expiry).toLocaleDateString(undefined, options);
        return expirydate;
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {desiredItem?.length > 0 ?
                <svg onClick={openModal}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="16" x2="12" y2="12" />
                    <line x1="12" y1="8" x2="12" y2="8" />
                </svg>
            
            : ""}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customModalStyle} 
            >
                {desiredItem?.length > 0 ?
                <>
                    <div className='modal-header'>
                        <strong>Session details: {}</strong>
                            <svg onClick={closeModal}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                color='#fff'
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{ cursor: "pointer" }} // Add cursor pointer style
                            >
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>

                    </div>
                    <div style={scrollableContentStyle}>
                        <table className="data-table session-table file-table-header">
                        <tbody>
                            <tr className='header'>
                                <th className='session'>Session</th>
                                {/* <th className='chunking-strategy'>Chunking Strategy</th> */}
                                <th className='expiringon'>Expiring on</th>
                                <th className='expiringon'>Created</th>
                                <th className='chunking-strategy'>Details</th>
                            </tr>
                            {desiredItem?
                                desiredItem.map((item, index)=>(
                                    <tr key={index}> 
                                        <td>{item.session_name}</td>
                                        {/* <td>{item.file_chunking_strategy}</td> */}
                                        <td>{getExpirationDate(item.created_date)}</td>
                                        <td>{item.is_processed === true ? "Yes" : "No"}</td>
                                        <td>{item.chunking_errors !== "" ? item.error_details : ""}</td>
                                    </tr>
                                )):""
                            }
                            </tbody>
                        </table>
                    </div>
                </>
                : ""
                }
            </Modal>
        </div>
    );
};

export default SessionTooltipButton;
