import * as actionTypes from "../action-types.js";

const initialState = {
  navigated: false
};

const navigationReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_NAVIGATED:
            return {
                ...state,
                navigated: action.payload
            }
      default:
        return state;
    }
}

export default navigationReducer;
