import * as actionTypes from "../action-types.js";

const initialState = {
  chatResponse: [],
  chatId: "",
  chatName: "",
  lastChat: "",
  chatHistoryloading: true
};

const chatReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.SET_CHAT_CONTENT:
            if(action.payload.data.length === 0){
                return {
                    chatId: action.payload.chatId,
                    chatResponse: action.payload.data,
                    chatName: "",
                    lastChat: "",
                    chatHistoryloading: false
                }
            }else{
                return {
                    ...state,
                    chatResponse: action.payload.data,
                    chatId: action.payload.chatId,
                    chatHistoryloading: false
                }
            }
        case actionTypes.SET_CHAT_RESPONSE:
            return {
                ...state,
                lastChat: action.payload,
                chatName: action.payload[1].chat
            }
        case actionTypes.SET_CHAT_ID:
            return {
                ...state,
                chatId: action.payload
            }
        case actionTypes.SET_CHAT_NAME:
            return {
                ...state,
                chatName: action.payload
            }
        case actionTypes.REFRESH_CHAT_DETAILS:
            return {
                chatResponse: [],
                chatId: "",
                chatName: "",
                lastChat: "",
                chatHistoryloading: true
            }
      default:
        return state;
    }
}

export default chatReducer;
