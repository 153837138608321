import * as actionTypes from "../action-types.js";

const initialState = {
  teamsFiles: [],
  loading: false,
  error: null,
  callForFile: false
};

const teamFilesReducer = (state= initialState, action) => {
   state.loading = true;
    switch(action.type){
      case actionTypes.SET_TEAM_FILES_FOLDERS_SUCCESS:
        return {
          ...state,
          teamFiles: action.payload.data,
          loading: false,
          callForFile: action.payload.callForFile
        }
      case actionTypes.SET_TEAM_FILES_FOLDERS_FAIL:
        return {
          ...state,
          error: action.error,
          loading: false,
        }
      default:
        return state;
    }
}

export default teamFilesReducer;
