import * as actionTypes from "../action-types.js";

const initialState = {
  teamList: [{ value: 'Loading your teams...', label: 'Loading your teams...' }],
  selectedTeam: { value: 'Loading your teams...', label: 'Loading your teams...' },
  loading: true,
  error: null,
  teamDropDownOptions: [{ value: 'Loading your teams...', label: 'Loading your teams...' }]
};

const teamListReducer = (state= initialState, action) => {
    switch(action.type){
      case actionTypes.SET_TEAM_LIST_SUCCESS:
        return {
          ...state,
          teamList: action.payload,
          selectedTeam: {value: action.payload[0].id, label: action.payload[0].displayName},
          loading: false,
        }
      case actionTypes.SET_TEAM_LIST_FAIL:
        return {
          ...state,
          error: action.error,
          loading: false,
        }
      case actionTypes.SET_SELECTED_TEAM:
        return {
          ...state,
          selectedTeam: action.payload,
        }
      case actionTypes.SET_TEAM_DROP_DOWN_OPTIONS:
        return {
          ...state,
          teamDropDownOptions: action.payload
        }
      default:
        return state;
    }
}

export default teamListReducer;
