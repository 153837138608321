import * as actionType from "../action-types.js";

const setChatListSuccess = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_LIST_SUCCESS,
            payload: data
        });
    };
};

const setChatListFailure= (error) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_LIST_FAIL,
            payload: error
        });
    }
}

const setChatListLoading = (loading) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_LIST_LOADING,
            payload: loading
        });
    }
}

export { setChatListSuccess, setChatListLoading, setChatListFailure };

