import React, { useState } from 'react';
import './chat-history.css'; // You can create a CSS file for styling
import {MdOutlineModeEditOutline, MdOutlineDelete, MdCheck} from 'react-icons/md';
import EmbeddingsApi from '../../api-client/api-embeddings';
import { setChatId, setChatName } from '../../state/action-creators/chat-action';
import { useDispatch, useSelector } from 'react-redux';
import { setChatListSuccess } from '../../state/action-creators/chat-list-actions';
import LoadingComponent from '../loading/loading.jsx';
import { RxCross2 } from 'react-icons/rx';

const RowComponent = ({ id, name}) => {
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editClick, setEditClick] = useState(false);
  const [newChatName, setNewChatName] = useState(name);

  const embeddingsApi = EmbeddingsApi();
  const dispatch = useDispatch();
  const chatList = useSelector((state) => state.chatlist.chatList);
  const currentChatId = useSelector((state) => state.chat_details.chatId);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const onChatClick = async (id)=>{
    if(!editClick){
      setLoading(true);
      dispatch(setChatName(name));
      dispatch(setChatId(id));
      await embeddingsApi.getChatHistory({chatId:id});
      setLoading(false);
    }
  } 

  const onChatDelete = async (id) => {
    const response = await embeddingsApi.deleteChat({chatId: id});
    if(response.data.status){
      const newChatList = chatList.filter((item) => item.chat_id !== id);
      dispatch(setChatListSuccess(newChatList));
    }
  }

  const onChatEdit = () => {
    setEditClick(true);
  }

  const onCheckClick = async(id) =>{

  }

  const onCrossClick = () =>{
    setEditClick(false);
  }

  const handleNameChange = (event) => {
    const value = event.target.value;
    setNewChatName(value);
  }

  return (
    <div
      className={id === currentChatId ? "row-component active":'row-component'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={()=>{onChatClick(id)}}
    >
      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
      {
        editClick 
          ? <>
              <input
                type="text"
                className="edit-input-field"
                style={{height: "24px"}}
                value={newChatName}
                onChange={handleNameChange}
                autofocus
              />
              <div className="options">
                <MdCheck viewBox='0 0 22 22' onClick={() => {onCheckClick(id)}}/>
                <RxCross2 onClick={onCrossClick}/>
              </div> 
            </>
          : <>
              <span className={id === currentChatId ? "chat-text-field active":'chat-text-field'}>{name}</span>
              {loading  ? <LoadingComponent loading={loading} /> : null}
              {hovered && !loading && (
                <div className="options">
                  {/* <MdOutlineModeEditOutline viewBox='0 0 22 22' onClick={onChatEdit}/> */}
                  <MdOutlineDelete viewBox='0 0 22 22' onClick={()=>{onChatDelete(id)}}/>
                </div> 
              )}      
            </> 
      }
    </div>
  );
};

export default RowComponent;