import * as actionType from "../action-types.js";

const setChatResponseLoading = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_RESPONSE_LOADING,
            payload: data
        });
    };
};

const setChatResponseSave = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionType.SET_CHAT_SAVE,
            payload: data
        });
    }
}

const setChatResponseError = (data) =>{
    return (dispatch)=>{
        dispatch({
            type: actionType.SET_RESPONSE_ERROR,
            payload: data
        });
    }
}

export { setChatResponseLoading, setChatResponseSave, setChatResponseError };

