import * as actionTypes from "../action-types.js";

const initialState = {
  selectedFiles: [],
  selectedCaseCloud: {},
  sessionName: {},
  selectAllClicked: false
};

const selectedFilesReducer = (state= initialState, action) => {
    switch(action.type){
      case actionTypes.SET_SELECTED_FILES:
        const currentId = action.payload.item.id;
        const prevSelectedFiles = [...state.selectedFiles];
        const updatedSelectedFiles = prevSelectedFiles.filter((item)=> item.id !== currentId);
        if(action.payload.checked === false){
            return {
                ...state,
                selectedFiles: updatedSelectedFiles,
            }
        }else{
            const newSelectedFile = {id:currentId, item: action.payload.item};
            const updatedNewSelectedFiles = updatedSelectedFiles.concat(newSelectedFile);
            return {
                ...state,
               selectedFiles: updatedNewSelectedFiles,
              }
        }
        case actionTypes.CLEAR_SELECTED_FILES:
            return {
                ...state,
                selectedCaseCloud: action.payload,
                selectedFiles: [],
            }
        case actionTypes.SET_SESSION_NAME:
            return {
                ...state,
                sessionName: action.payload
            }
        case actionTypes.SET_CLEAR_CURRENT_SESSION:
            return {
                selectedFiles: [],
                selectedCaseCloud: {},
                sessionName: {},
                selectAllClicked: false
            }
        case actionTypes.SET_SELECT_ALL_CLICKED:
            return {
                ...state,
                selectAllClicked: action.payload
            }
      default:
        return state;
    }
}

export default selectedFilesReducer;
