import { useEffect } from "react";
import EmbeddingsApi from "../../api-client/api-embeddings";
import FilePanelComponent from "../file-side-panel/file-panel";
import FileExplorer from "../file-explorer/file-explorer-view/file-explorer";
import { useDispatch, useSelector } from "react-redux";
import { setNavigated } from "../../state/action-creators/navigation-action";

const EmbeddingPage = () =>{
    const embeddingsApi = EmbeddingsApi();
    const dispatch = useDispatch();
    const teams = useSelector((state)=> state.team_list.teamList);

    useEffect(()=>{
        async function getEmbedParams(){
            await embeddingsApi.getEmbeddingParams();
        }
        getEmbedParams();
    },[]);

    useEffect(()=>{
        async function fetchData(){
            await embeddingsApi.getTeams();
            dispatch(setNavigated(false));
        }
        // call shouldn't be made if data is already fetched on home page.
        if(teams === undefined || teams === null || teams.length === 0 || 
            (teams.length === 1 && teams[0].value === "Loading your teams..."))
            fetchData();
    },[]);

    return (
        <>
            <FilePanelComponent embeddingsApi={embeddingsApi}/>
            <div className="right-view-container">
                <FileExplorer></FileExplorer>
            </div>
        </>
    );

}

export default EmbeddingPage;